import React from 'react';
import General from "../General";
import Footer from "../../Components/Footer";

class MFA extends React.Component{

  waveContent = () => {
    return (
      <main style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <div style={{ maxWidth: "1000px", width: "100%", padding: "0.5em" }}>

          <h2 style={{
            textAlign: "center",
            margin: "5rem 0rem 3rem 0rem",
            color: "#595cff"
          }}>
            Master Services And API Licence Agreement
          </h2>


          <object
            data="/Spade MSA - Website Version.pdf"
            style={{
              width: "100%",
              height: "1000px"
            }}
          />




        </div>
      </main>
    );
  }

  render() {
    return (
      <React.Fragment>
        <General customWave={this.waveContent()} aboveWave={false} />
        <Footer />
      </React.Fragment>

    );
  }

}

export default MFA;

import React from 'react';
import { Link } from "@reach/router";
import 'react-json-pretty/themes/monikai.css';
import Paper from "@material-ui/core/Paper";
import General from "../General";
import Code from "../../Components/Code";
import SpadeLogo from "../../Components/SpadeLogo";
import JSONPretty from "react-json-pretty";


class QuickStart extends React.Component {

  render() {
    return (
      <General>
        <main
          style={{
            display: "flex",
            margin: "auto",
            position: "relative",
            zIndex: 10,
            minHeight: "500px",
            flexDirection: "column",
            // fontFamily: "Arial",
            fontSize: "12pt"
          }}
        >
          <Paper elevation={2} style={{ padding: "4em 8%", color: "#555" }}>
            <SpadeLogo style={{ float: "right" }} size="75px" />
            <h1>Spade Quick Start Guide</h1>

            <br/>
            <h2>What is Spade?</h2>
            <p>
              Spade is a restful API for enriching your customer's transaction data with high quality logos, geo location data,
              AI normalized merchant names, and flexible categorization.
              The API is designed to be simple and painless to set up, and will allow you to begin providing a better product immediately.
            </p>

            <br/>
            <h2>An Example Enrichment</h2>
            <p>
              For example, take a messy transaction name such as
              <Code>
                Purchase from AUTOZONE #3907 MEBANE, NC, US | **1144
              </Code>
              and enrich it to find a logo and location with a single call to
              <Code>/transactions/enrich</Code>.
            </p>
            <div className="fr ac jc" style={{ transform: "scale(0.95)" }}>
              <div className="fc ac jc">
                <div style={{ marginBottom: "1em", fontWeight: "bold"}}>POST Request</div>
                <Paper elevation={3} style={{ overflow: "hidden", borderRadius: "10px" }}>
                  <JSONPretty
                    data={{
                      "transaction_name": "Purchase from AUTOZONE #3907 MEBANE, NC, US | **1144"
                    }}
                    style={{ background: "#272822", padding: "0.5em 2em 0.5em 0.5em", color: "#5f4dee" }}
                  />
                </Paper>
              </div>
              <div style={{ margin: "1.5em 1em 0em 1em", fontSize: "14pt", }}>
                &#8594;
              </div>
              <div className="fc ac jc">
                <div style={{ marginBottom: "1em", fontWeight: "bold"}}>Response</div>
                <Paper elevation={3} style={{ overflow: "hidden", borderRadius: "10px" }}>
                  <JSONPretty
                    style={{ background: "#272822", padding: "0.5em 2em 0.5em 0.5em", color: "#5f4dee" }}
                    data={{
                      "normalized_merchant_name": "Auto Zone",
                      "location": {
                        "address": "1052 Mebane Oaks Rd",
                        "city": "Mebane",
                        "state": "NC",
                        "zip_code": "27302-9679",
                        "latitude": 36.07407,
                        "longitude": -79.27388
                      },
                      "logo": {
                        "name": "autozone",
                        "path": "/logos/verified/autozone.png",
                        "verified": true,
                        "type": "image/png"
                      },
                      "category": ["Retailer",	"Automotive",	"Car Parts and Accessories"],
                      "is_recurring": false,
                      "merchant_info": {
                        "verified": true,
                        "name": "Auto Zone",
                        "website": "autozone.com",
                      }
                    }}
                  />
                </Paper>
                <div style={{ margin: "1em 0em 0em 0em", fontSize: "14pt", }}>
                  &#8595;
                </div>
                <div style={{ fontWeight: "bold" }}>
                  Found Logo
                </div>
                <Paper elevation={3} style={{ padding: "0.5em", marginTop: "0.5em" }} className="fc ac jc">
                  <img src="https://v1.spadeapi.com/logos/verified/autozone.png?size=medium" width="350px" alt="merchant logo"/>
                </Paper>
              </div>
            </div>
            <p style={{ textAlign: "center" }}>
              <strong>Note!</strong> Spade can handle much more complex requests involving incomplete location data, user ids, and categories.
            </p>

            <br/>
            <h2>Getting Started</h2>
            <p>
              Setting up Spade takes only a few steps. Many have completed their integrations in under an hour.
              <ol>
                <li>Get Access</li>
                <li>Locate your API key</li>
                <li>Start making requests</li>
              </ol>
            </p>

            <h4>1. Get Access</h4>
            <p>
              Please click "Request API Access" on our <a href="/">home page</a>.
              We'll more than likely get back to you within one day and you'll be ready to begin enriching transactions.
            </p>

            <h4>2. Locate your API keys</h4>
            <p>
              Welcome to Spade. Once you've confirmed your email address. You'll be presented with our dashboard, here you can manage your account and
              view data regarding your enrichments.
            </p>
            <ul>
              <li>
                <div className="fr ac">
                  <div>
                    Click on <strong>Account Settings</strong> from the top bar
                  </div>
                  <img src="/quickstart/accountsettings.png" width="150px" />
                </div>
              </li>
              <li>
                <div className="fr ac jc">
                  <div>
                    Here you should see a table with your obscured API keys. Click the lock button to un-obscure them.
                    Now select, and copy one of the keys to your clipboard.
                  </div>
                  <img src="/quickstart/copykey.png" width="500px" />
                </div>
              </li>
            </ul>

            <h4>3. Make a request</h4>
            <p>
              We're ready to enrich our first transaction now.
            </p>
            <p>
              To perform a basic enrichment we'll make a
              <Code>POST</Code> request to <Code>https://v1.spadeapi.com/transactions/enrich</Code>.
              Spade uses bearer token authorization so you'll need a header that looks something like this
              <Code>Authorization: Bearer you-api-key-goes-here</Code>
            </p>
            <p>
              Feel free to make a request using your own data or you can use our example from earlier.
              Dont forget to use your new api key in the example.
            </p>
            <strong>
              Example curl request:
            </strong>
            <div>
              <Code>
                curl -XPOST \
                <br/>-H 'Authorization: Bearer you-api-key-goes-here' \
                <br/>-H 'Content-Type: application/json' \
                <br/>-d '&#123;"transaction_name": "Purchase from AUTOZONE #3907 MEBANE, NC, US | **1144"&#125;' \
                <br/>https://v1.spadeapi.com/transactions/enrich
              </Code>
            </div>

            <p>
              <strong>Ta-Da!</strong>
            </p>

            <p>
              That's all there is to it. In many cases this is all you need to add to your system to start finding logos and locations for your transactions.
              However, there are many more available parameters to improve the efficacy of your requests.
            </p>

            <br/>
            <h2>Whats Next?</h2>
            <p>
              Above is the most simple request possible, we all of for many more fields besides transaction name,
              and we encourage you to include them to improve efficacy of your enrichments.
            </p>
            <p>
              <strong>See <Link to="/docs">our docs</Link> for more information regarding these options.</strong>
            </p>
            <p>
              Have questions? <a href="mailto:cto@spade.dev">Email us</a>
            </p>
          </Paper>
        </main>
      </General>
    );
  }
}

export default QuickStart;

import React from 'react';
import PortalTab from "../PortalTab";
import "./Reports.css";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SuperCategory from "./SuperCategory";

class Reports extends React.Component {

  constructor(props) {
    super(props);
    // TODO - Hit endpoint to collect metrics for the month
  }

  render() {
    const { } = this.props;
    return (
      <PortalTab
        title="Super Categories"
        description="Super Categories are Collections of Merchant Categories for Organizing, Budgeting and Analysis"
        right={(
          <div className="fr ac" style={{ width: "250px", fontSize: "10.5pt", marginRight: "35px", marginLeft: "auto" }}>
            <InfoOutlinedIcon fontSize="small" style={{ marginRight: "0.5em"}} />
            <div>Saved changes may take up to 30 minutes to take effect.</div>
          </div>
        )}
      >
        <div className="fr" style={{ flexWrap: "wrap" }}>
          <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, minWidth: "60%"}}>
            <SuperCategory />
            <SuperCategory />
            <SuperCategory />
            <SuperCategory />
            <SuperCategory />
          </div>
          <div style={{ fontSize: "11pt", paddingLeft: "2em", width: "300px" }}>
            <h4>About Super Categories</h4>
            <p>Super Categories are groups of categories that can be used for organization and performing analysis.</p>
            <p>Manage and create individual categories in the 'Categories' tab and then organize them into super categories on this page.</p>
            <p>Via the API, fetch reports of a users spending broken down according to the Super Categories your define here. </p>
          </div>
        </div>
      </PortalTab>
    );
  }
}

export default Reports;

import React from 'react';
import {BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, ResponsiveContainer} from "recharts";

class UsageGraph extends React.Component {
  render() {
    const { usage } = this.props;
    const data = usage.slice(0,12); // Only show previous 5 months
    return (
      <div style={{ flexGrow: 1, maxWidth: "100%", minWidth: "50%", marginRight: "1em" }}>
        <ResponsiveContainer height={400} width="100%">
          <BarChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="5 5" vertical={false} strokeWidth={2} />
            <XAxis dataKey="month" reversed />
            <YAxis allowDecimals={false} />
            <Tooltip isAnimationActive={false} />
            <Legend />
            <Bar dataKey="Enriched" fill="#5f4dee" maxBarSize={20} />
            <Bar dataKey="Logos" fill="#1F98E0" maxBarSize={20} />
            <Bar dataKey="Locations" fill="#33CC7D" maxBarSize={20} />

          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default UsageGraph;

import React from "react";
import examples from "../Scenes/Home/examples";
import { navigate } from "@reach/router";
import Paper from "@material-ui/core/Paper";
import JSONPretty from "react-json-pretty";
import FancyImage from "./FancyImage";

// Beautiful UIs
// Fraud Detection
// Loan Underwriting

class Applications extends React.Component {


  application = (title, bullets, image, reverse=false) => {
    return (
      <div style={{ width: "100%", display: "flex", flexDirection: !reverse ? "row" : "row-reverse", alignItems: "center", justifyContent: "center", gap: "50px", flexWrap: "wrap"}}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "350px" }}>
          <h2 className="fancytext" style={{ fontSize: "25pt", textAlign: "center", marginBottom: "1em" }}>{title}</h2>
          {/*<FancyImage src={image} height="200px" width="200px" style={{ marginTop: "auto"}}/>*/}
          <img src={image} height="200px" style={{ marginTop: "auto"}}/>
        </div>
        <div style={{ fontSize: "17pt", maxWidth: "600px", color: "#555", marginTop: "0px" }}>
          <ul>
            {bullets.map((b) => {
              return <li style={{ margin: "20px 0px"}}>{b}</li>
            })}
          </ul>
        </div>
      </div>
    )
  }

  render(){
    return (
      <div style={{ marginTop: "100px", width: "90%", marginBottom: "80px"}}>
          {/*<h2 className="fancytext" style={{ textAlign: "center" }}>Applications</h2>*/}
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "25px", flexWrap: "wrap"}}>
            {this.application(
              "Build Beautiful Applications",
              [
                <span>Instantly build <b>beautiful transaction feeds</b> using high definition logos</span>,
                <span>Present customers with <b>easily understood spending</b> using cleaned and normalized merchant names</span>,
              ],
              "/user-interface.png"
            )}
            {this.application(
              "Understand Your Customers",
              [
                <span>Utilize Spades granular categorization engine to <b>underwrite safely and quickly</b></span>,
                <span>Quickly <b>detect fraud</b> using expanded granular geo data</span>,
                <span><b>Improve financial outcomes</b> by understanding customer purchasing habits instantly</span>
              ],
              "/data-visualization.png",
              true
            )}
          </div>
      </div>
    )
  }

}

export default Applications;

import React, {useState} from 'react';
import General from "../General";
import { CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import TextBox from "../../Components/TextBox";
import BigButton from "../../Components/BigButton";
import {Link, navigate} from "@reach/router";
import { useIdentityContext } from "react-netlify-identity"

function NewPassword(props){
  const [ password, setPassword ] = useState("");
  const [ password2, setPassword2 ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);

  const { updateUser } = useIdentityContext();

  const signUp = () => {
    if (password.length < 1 || password2.length < 1) {
      return setError("All fields are required");
    }
    if (password != password2) {
      return setError("Passwords do not match")
    }
    setLoading(true);
    setError();
    updateUser({ password: password })
      .then((user) => {
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
      })
  }

  return (
    <General>
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
          position: "relative",
          zIndex: 10,
          width: "100%",
          boxSizing: "border-box",
          marginTop: "70px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "450px" }}>
          <h1>Change Password</h1>
          <TextBox onEnter={signUp} disabled={loading} label="New Password" placeholder="Password" type="password" onChange={(e) => { setPassword(e.target.value); }}/>
          <TextBox onEnter={signUp} disabled={loading} label="Repeat Password" placeholder="Password" type="password" onChange={(e) => { setPassword2(e.target.value); }}/>
          {loading ? (
            <CircularProgress color="white" style={{ marginTop: "0.5em"}}/>
          ) : (
            <BigButton content="Set Password" onClick={signUp}/>
          )}

        </div>
      </main>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={error} autoHideDuration={4000} onClose={() => { setError() }} >
        <MuiAlert severity="error" variant="filled">{error}</MuiAlert>
      </Snackbar>
    </General>
  );
}

export default NewPassword;

import {TextField} from "@material-ui/core";
import React from "react";

class TextBox extends React.Component {

  render(){
    const { multiline=false, value, className="", style={}, label, placeholder, type, name, id, onChange=()=>{}, onEnter=()=>{}, disabled=false, size=null, inputProps={} } = this.props;
    return (
      <div
        style={{
          borderRadius: "15px",
          overflow: "hidden",
          height: "56px",
          flexGrow: 1,
          marginBottom: "0.5em",
          ...style
        }}>
        <TextField
          rows={4}
          multiline={multiline}
          autoComplete="off"
          InputProps={{
            disableUnderline: true,
            ...inputProps
          }}
          className={className}
          value={value}
          size={size}
          name={name}
          type={type}
          id={id}
          label={label}
          placeholder={placeholder}
          variant="filled"
          onChange={onChange}
          onKeyDown={(e) => { if(e.key === "Enter") onEnter(); }}
          disabled={disabled}
          style={{
            background:"white",
            opacity: disabled ? 0.6 : 1,
            color: "darkslategrey",
            width: "100%",
            transition: "opacity 0.5s",
          }}
        />
      </div>
    )
  }

}

export default TextBox;
import React from 'react';
import General from "../General";
import {  Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import RequestAccessDialog from "../SignIn/RequestAccessDialog";
import TrustedBy from "./TrustedBy";
import Footer from "../../Components/Footer";
import UpgradeYourData from "./UpgradeYourData";
import ExampleEnrichments from "../../Components/ExampleEnrichments";
import SpadeLogo from "../../Components/SpadeLogo";
import Applications from "../../Components/Applications";
import BigButton from "../../Components/BigButton";
import {Spa} from "@material-ui/icons";
import {Link} from "@reach/router";

class CommingSoon extends React.Component{

  state = {
    emailError: false,
    requestDialog: false,
    countDown: 3
  }

  handleClose = () => {
    this.setState({requestDialog: false})
  }

  waveContent = () => {
    return (
      <Footer />
    )
  }

  componentDidMount = () => {

    setInterval(() => {
      if (this.state.countDown <= 0) {
        window.location.href = "https://www.spade.com";
      } else {
        this.setState((state) => { return { countDown: state.countDown-1 }; })
      }
    }, 1000)
  }

  render() {



    const { emailError, example, requestDialog, countDown } = this.state;
    // const ex = examples[example % examples.length];
    return (
      <General customWave={this.waveContent()} hideLogo>
        <main
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            margin: "auto",
            alignItems: "center",
            position: "relative",
            zIndex: 10,
            width: "100%",
            boxSizing: "border-box",
            height: "60vh",
          }}>
            <img alt="icon" src="/Spade_Icon_REB_rev.png" height="80px" style={{ marginBottom: "2em" }}/>
            {/*<img alt="spade" src="/Spade_Primary_RGB_rev.png" width="150px"/>*/}
            <div style={{ fontSize: "20pt", marginTop: "0.5em", textAlign: "center" }}>Spade has a new website at <a href="https://spade.com">Spade.com!</a></div>
            <div style={{ fontSize: "12pt" }}>Redirecting in <span style={{ width: "20px" }}>{countDown}</span></div>
            <a href="https://spade.com" style={{ fontSize: "10pt", color: "white" }}>Click here if you are not redirected</a>

        </main>
        <Snackbar
          open={emailError}
          autoHideDuration={3000}
          onClose={() => { this.setState({ emailError: false })}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MuiAlert severity="error" elevation={100} variant="filled">Invalid Email Address</MuiAlert>
        </Snackbar>
        {requestDialog && <RequestAccessDialog onClose={this.handleClose} />}

      </General>
    );
  }

}

export default CommingSoon;

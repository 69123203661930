import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Application from "./Application";
import { IdentityContextProvider } from "react-netlify-identity";

ReactDOM.render(
  <React.Fragment>
    <IdentityContextProvider url="https://www.spade.dev">
      <Application />
    </IdentityContextProvider>
  </React.Fragment>,
  document.getElementById('root')
);

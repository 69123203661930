import starbucks from "../../images/logos/starbucks.png";
import seveneleven from "../../images/logos/7eleven.png";
import autozone from "../../images/logos/autozone.png";
import cashapp from "../../images/logos/cashapp.png";
import cvs from "../../images/logos/cvs.png";
import murphy from "../../images/logos/murphy.png";
import netflix from "../../images/logos/netflix.png";
import xfinity from "../../images/logos/xfinity.png";

const examples = [
  {
    request: {
      "merchant_name": "STARBUCKS STORE 07837",
      "transaction_name": "STARBUCKS STORE 07837 | MEDIA, PA | **2413",
    },
    response: {
      "normalized_merchant_name": "Starbucks",
      "location": {
        "address": "443 East Baltimore Avenue",
        "city": "Media",
        "state": "PA",
        "zip_code": "19063",
        "latitude": 39.91597,
        "longitude": -75.38095
      },
      "logo": {
        "path": "/logos/verified/starbucks.png",
        "verified": true,
        "type": "image/png"
      },
      "category": ["Leisure",	"Food and Drink",	"Restaurants",	"Coffee Shop"],
      "is_recurring": false,
      "merchant_info": {
        "verified": true,
        "name": "Starbucks Coffee",
        "website": "starbucks.com",
      }
    },
    image: starbucks
  },
  {
    request: {
      "merchant_name": "MURPHY EXPRESS 8, 6076 COVINGTON HWY",
      "mcc": 5983
    },
    response: {
      "normalized_merchant_name": "Murphy USA",
      "location": {
        "address": "6076 Covington Hwy",
        "city": "Decatur",
        "state": "GA",
        "zip_code": "30035-3801",
        "latitude": 33.72365,
        "longitude": -84.16925
      },
      "logo": {
        "path": "/logos/verified/murphy.png",
        "verified": true,
        "type": "image/png"
      },
      "category": ["Retailer",	"Fuel"],
      "merchant_info": {
        "verified": true,
        "name": "Murphy USA",
        "website": "murphyusa.com",
      }
    },
    image: murphy
  },
  {
    request: {
      "merchant_name": "Netflix.com",
      "transaction_name": "Purchase from Netflix.com CA | **2413"
    },
    response: {
      "normalized_merchant_name": "Netflix",
      "logo": {
        "path": "/logos/verified/netflix.png",
        "verified": true,
        "type": "image/png"
      },
      "is_recurring": true,
      "merchant_info": {
        "verified": true,
        "name": "Netflix",
        "website": "netflix.com",
        "has_physical_locations": false
      }
    },
    image: netflix
  },
  {
    request: {
      "transaction_name": "7-ELEVEN, 701 S CRATER RD PETERSBURG, VA"
    },
    response: {
      "normalized_merchant_name": "7-Eleven",
      "location": {
        "address": "701 S Crater Rd",
        "city": "Petersburg",
        "state": "VA",
        "zip_code": "23803",
        "latitude": 37.21777,
        "longitude": -77.38293
      },
      "logo": {
        "path": "/logos/verified/7eleven.png",
        "verified": true,
        "type": "image/png"
      },
      "category": ["Retailer",	"Convenience Stores"],
      "is_recurring": false,
      "merchant_info": {
        "verified": true,
        "name": "7-Eleven",
        "website": "7-eleven.com",
      }
    },
    image:  seveneleven
  },
  {
    request: {
      "merchant_name": "CASH APP*RICK ASTLEY*ADD",
    },
    response: {
      "normalized_merchant_name": "Cash App",
      "logo": {
        "path": "/logos/verified/cashapp.png",
        "verified": true,
        "type": "image/png"
      },
      "category": ["Financial",	"Transfer"],
      "is_recurring": false,
      "merchant_info": {
        "verified": true,
        "name": "Cash App",
        "website": "cash.app",
      }
    },
    image: cashapp
  },
  {
    request: {
      "merchant_name": "AUTOZONE #3907",
      "transaction_name": "Purchase from AUTOZONE #3907 MEBANE, NC **1144"
    },
    response: {
      "normalized_merchant_name": "Auto Zone",
      "location": {
        "address": "1052 Mebane Oaks Rd",
        "city": "Mebane",
        "state": "NC",
        "zip_code": "27302-9679",
        "latitude": 36.07407,
        "longitude": -79.27388
      },
      "logo": {
        "path": "/logos/verified/autozone.png",
        "verified": true,
        "type": "image/png"
      },
      "category": ["Retailer",	"Automotive",	"Car Parts and Accessories"],
      "is_recurring": false,
      "merchant_info": {
        "verified": true,
        "name": "Auto Zone",
        "website": "autozone.com",
      }
    },
    image: autozone
  },
  {
    request: {
      "merchant_name": "XFINITY MOBILE",
      "transaction_name": "Purchase from XFINITY MOBILE 888-936-4968 PA  **4637"
    },
    response: {
      "normalized_merchant_name": "Xfinity",
      "logo": {
        "path": "/logos/verified/xfinity.png",
        "verified": true,
        "type": "image/png"
      },
      "category": ["Service",	"Telecommunication Services"],
      "is_recurring": true,
      "merchant_info": {
        "verified": true,
        "name": "Xfinity",
        "website": "xfinity.com",
      }
    },
    image: xfinity
  },
  {
    request: {
      "merchant_name": "CVS/PHARMACY #05, 05087--15 SOUTH MAI",
      "transaction_name": "CVS/PHARMACY #05, 05087--15 SOUTH MAI JAMESTOWN, NY**0541"
    },
    response: {
      "normalized_merchant_name": "CVS",
      "location": {
        "address": "19 South Main Street",
        "city": "Jamestown",
        "state": "NY",
        "zip_code": "14701",
        "latitude": 42.09357,
        "longitude": -79.23865
      },
      "logo": {
        "path": "/logos/verified/cvs.png",
        "verified": true,
        "type": "image/png"
      },
      "category": ["Retailer",	"Pharmacies"],
      "is_recurring": false,
      "merchant_info": {
        "verified": true,
        "name": "CVS",
        "website": "cvs.com",
      }
    },
    image: cvs
  }
]

export default examples;
import React from 'react';
import { Paper, Button, IconButton } from "@material-ui/core";
import TextBox from "../../../Components/TextBox";
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const diameter = "20px";

class SuperCategory extends React.Component {

  state = {
    editingTitle: false,
    editTitle: "",
    expanded: true,
  }

  saveTitleChange = () => {
    const { editTitle } = this.state;
  }

  render() {
    const { editingTitle, editTitle, expanded } = this.state;
    const { superCategory = { title: "Leisure", categories: ["toys", "food"], color: "#F44336" } } = this.props;
    const {
      title,
      categories,
      color,
    } = superCategory;
    return (
      <Paper elevation={2} style={{ padding: "1em", color: "#555", flexGrow: 1, marginBottom: "1em" }} className="fc">
        <div className="fc">
          <div className="fr ac">
            <div
              style={{
                width: diameter,
                height: diameter,
                borderRadius: "100px",
                backgroundColor: color,
                marginRight: "0.5em",
              }}
            />
            {editingTitle ? (
              <div  className="fr ac">
                <TextBox
                  label="Title"
                  placeholder="e.g. Leisure"
                  style={{ flexGrow: 0 }}
                  value={editTitle}
                  onChange={(e) => { this.setState({ editTitle: e.target.value }); }}
                />
                <IconButton size="small" title="Save Changes" onClick={this.saveTitleChange}>
                  <CheckCircleIcon style={{ color: "green" }}/>
                </IconButton>
                <IconButton size="small" onClick={() => { this.setState({ editingTitle: false }); }} title="Cancel Changes">
                  <CloseIcon />
                </IconButton>
              </div>
            ) : (
              <div onClick={() => { this.setState({ editingTitle: true, editTitle: superCategory.title }); }}>
                <h2 style={{ margin: "0", display: "inline" }}>
                  {title}
                </h2>
              </div>
            )}

            <div style={{ marginLeft: "0.5em", fontSize: "11pt", color: "#777" }}>
              Contains <b>{categories.length}</b> Categories
            </div>
          </div>
          <div style={{ fontSize: "11pt", color: "#777" }}>
            This is a description of this super category. This is a very nice super category and I love it very much.
          </div>
        </div>
        <div>
          {expanded && (
            <div className="fr">
              {categories.map((c) => {
                return (
                  <div>
                    {c},&nbsp;
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Paper>
    );
  }
}

export default SuperCategory;

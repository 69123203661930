import React from 'react';
import PortalTab from "../PortalTab";
import SpendingClass from "../Reports/SpendingClass";
import SuperCategory from "../Reports/SuperCategory";
import BigButton from "../../../Components/BigButton";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

class Categories extends React.Component {

  constructor(props) {
    super(props);
    // TODO - Hit endpoint to collect metrics for the month
  }

  render() {
    const { } = this.props;
    return (
      <PortalTab
        title="Merchant Categories"
        description="Configure Merchant Categories and Rules For Your Product"
        right={(
          <div className="fr ac" style={{ width: "250px", fontSize: "10.5pt", marginRight: "35px", marginLeft: "auto" }}>
            <InfoOutlinedIcon fontSize="small" style={{ marginRight: "0.5em"}} />
            <div>Saved changes may take up to 30 minutes to take effect.</div>
          </div>
        )}
      >
        <div className="fr" style={{ flexWrap: "wrap" }}>
          <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, minWidth: "60%"}}>
            Category Stuff
          </div>
          <div style={{ fontSize: "11pt", paddingLeft: "2em", width: "300px" }}>
            <h4>About Categories</h4>
            <p>Configure rules on categories to correct for false positives & negatives from your provider.</p>
            <p>
              Rule Types:
              <ul style={{ fontWeight: "bold" }}>
                <li>Key Word</li>
                <li>Regex</li>
                <li>Category Forwarding</li>
              </ul>
            </p>
          </div>
        </div>
      </PortalTab>
    );
  }
}

export default Categories;

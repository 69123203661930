import React from 'react';
import Paper from "@material-ui/core/Paper";
import { KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, CircularProgress } from "@material-ui/core";

class FilterMenu extends React.Component {

  state = {
    startDate: Moment().subtract(29, 'days'),
    endDate: Moment(),
  }

  load = () => {
    const { startDate, endDate } = this.state;
    const { loadFunc} = this.props;
    const start = startDate.startOf('day').toISOString();
    const end = endDate.endOf('day').toISOString();
    loadFunc(start, end);
  }

  componentDidMount() {
    const { loadOnStart=false } = this.props;
    if (loadOnStart) {
      this.load();
    }
  }

  render() {
    const { startDate, endDate } = this.state;
    const { loading } = this.props;
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Paper
            elevation={2}
            style={{
              display: "flex",
              flexDirection: "row",
              flexBasis: "200px",
              padding: "1em",
              flexWrap: "wrap",
              height: "min-content",
              flexGrow: 1,
              color: "#555",
              gap: "0.5em"
            }}
          >
            <div>
              <h5 style={{ margin: "0" }}>
                Date Range:
              </h5>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label="Earliest"
                value={startDate}
                onChange={(date) => { this.setState({ startDate: date }); }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                style={{ maxWidth: "200px", marginTop: "0.5em" }}
                disableFuture
                autoOk
              />
              <KeyboardDatePicker
                disableToolbar
                minDate={startDate}
                variant="inline"
                format="DD/MM/YYYY"
                margin="normal"
                label="Latest"
                value={endDate}
                onChange={(date) => { this.setState({ endDate: date }); }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                style={{ maxWidth: "200px", marginTop: "0.5em" }}
                disableFuture
                autoOk
              />
              <div className="fr ac" style={{ gap: "0.5em", flexWrap: "wrap" }}>
                <Button
                  size="small" variant="outlined" style={{ textTransform: "none" }}
                  onClick={() => {
                    this.setState({ startDate: Moment().subtract(29, 'days'), endDate: Moment() }, this.load)}}
                >
                  Past 30 Days
                </Button>
                <Button
                  size="small" variant="outlined" style={{ textTransform: "none" }}
                  onClick={() => {
                    this.setState({ startDate: Moment().subtract(59, 'days'), endDate: Moment().subtract(30, 'days') }, this.load); }}
                >
                  60-30 Days
                </Button>
                <Button
                  size="small" variant="outlined" style={{ textTransform: "none" }}
                  onClick={() => {
                    this.setState({ startDate: Moment().subtract(6, 'days'), endDate: Moment() }, this.load ); }}
                >
                  Past 7 Days
                </Button>
                <Button
                  size="small" variant="outlined" style={{ textTransform: "none" }}
                  onClick={() => { this.setState({ startDate: Moment().startOf('day'), endDate: Moment() }, this.load); }}
                >
                  Today
                </Button>
              </div>
            </div>
            {/*<div>*/}
            {/*  <h5 style={{ margin: "0 0 0.5em 0", minWidth: "150px" }}>Categories:</h5>*/}
            {/*  <div>*/}
            {/*    Todo*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="fr ac jc" style={{ marginLeft: "auto", width: "100px", height: "max-content", marginTop: "auto" }}>
              {loading ? (
                <CircularProgress color="primary" style={{  margin: "auto" }} />
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={this.load}
                  loading={true}
                >
                  Filter
                </Button>
              )}
            </div>

          </Paper>
        </MuiPickersUtilsProvider>
    );
  }
}

export default FilterMenu;

import React from 'react';
import { CircularProgress} from "@material-ui/core";

class PortalTab extends React.Component {


  render() {
    const { title, children, loading=false, description, right=null } = this.props;
    return (
      <div style={{ color: "#555" }} >
        <div style={{ marginBottom: "1em", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div>
            <h2 style={{ marginTop: 0, marginBottom: "0"}}>{title}</h2>
            <div style={{ fontSize: "11pt" }}>{description}</div>
          </div>
          {!loading && (
            <div style={{ flexGrow: 1 }}>
              {right}
            </div>
          )}
        </div>
        {loading ? (
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <CircularProgress color="primary" style={{  margin: "auto" }} />
          </div>
        ) : (
          <React.Fragment>
            {children}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default PortalTab;

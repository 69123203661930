import React from "react";

class Code extends React.Component {

  render(){
    return (
      <div
        style={{
          fontFamily: "mono, monospace",
          backgroundColor: "#eee",
          padding: "0.2em 0.5em",
          borderRadius: "4px",
          color: "#5f4dee",
          margin: "1px 0.25em",
          display: "inline-block"
        }}
      >
        {this.props.children}
      </div>
    )
  }

}

export default Code;
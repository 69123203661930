import React from 'react';
import iphone from "../../images/iphone.png";
import map from "../../images/map.png";
import icon from "../../images/icon.png";
import home from "../../images/home.png";
import card from "../../images/credit-card.png";
import menu from "../../images/menu.png";
import thunder from "../../images/thunder.png";
import reset from "../../images/reset.png";
import "./phonestyle.css"
import upgrades from "./upgradeexamples"
import BigButton from "../../Components/BigButton";

const cleaned = 1;
const categories = 2
const logos = 3;
const recurring = 4;
const geo = 5;



class UpgradeYourData extends React.Component {

  state = {
    stage: 1,
  }

  componentDidMount() {

    this.interval = setInterval(() => {
      this.setState({ stage: (this.state.stage + 1) % 8})
    }, 1700)

  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  expenses = () => {
    const { stage } = this.state
    return (
      <div style={{ display: "grid", gridTemplateColumns: "auto auto auto"}}>
        {upgrades.map((u) => {
          return (
            <React.Fragment>
              <div className="fr jc ac transition_width" style={{ maxWidth: stage > logos ? "35px" : "0px", marginBottom: "9px"}}>
                <img className="transition_opacity" src={u.logo} style={{ maxHeight: "30px", maxWidth: "35px", opacity: stage > logos ? 1: 0 }}/>
              </div>
              <div className="fc jc" style={{ marginBottom: "5px", height: "40px"}}>
                <div className="transition_opacity" style={{ width: 0, height: 0, transform: "translateX(-18px) translateY(17px)", opacity: u.recurring && stage > recurring ? 1: 0 }}>
                  <img className="spin" src={reset} width="12px" />
                </div>
                <div style={{ position: "relative", width: "115%" }}>
                  <div className="transition_opacity" style={{ fontWeight: "600", fontSize: "9pt", position: "absolute", top: 0, opacity: stage > cleaned ? 1 : 0 }}>
                    {u.clean_name}
                  </div>
                  <div className="transition_opacity" style={{ fontSize: "7.5pt", position: "absolute", top: 0, opacity: stage > cleaned ? 0 : 1 }}>
                    {u.original_name}
                  </div>
                </div>
                <div className="transition_opacity" style={{ opacity: stage > categories ? 1 : 0, marginTop: "1.2em", fontSize: "8pt" }}>
                  {u.category}
                </div>
              </div>
              <div className="fc jc" style={{ marginLeft: "auto", textAlign: "right" }}>
                <div style={{ fontWeight: "bold", fontSize: "8.5pt"}}>
                  ${u.value}
                </div>
                <div style={{ fontSize: "8pt"}}>
                  {u.date}
                </div>
              </div>
            </React.Fragment>
          )
        })}
      </div>
    )
  }

  phoneScreen = () => {
    const { stage } = this.state;
    return (
      <div
        className="fancybackground"
        style={{ width: "100%", height: "100%", overflow: "hidden", position: "relative", fontSize: "9pt" }}
      >
        <div
          style={{
            color: "white",
            width: "100%",
            height: "5%",
            fontSize: "7pt",
            fontWeight: "bold",
            padding: "5% 10% 1% 15%",
            boxSizing: "border-box",
          }}
          className="fr"
        >
          <div>5:04</div>
          <div style={{ marginLeft: "auto" }}>
            <img src={icon} width="40px" />
          </div>
        </div>
        <div style={{ padding: "0% 3.5%" }}>
          <div
            className="transition_height"
            style={{
              background: "rgba(0,0,100,0.3)",
              borderRadius: "20px",
              width: "97.5%",
              margin: "auto",
              height: "199px",
              overflow: "hidden",
              maxHeight: stage > geo ? "200px": "0px",
              opacity: stage > geo ? 1 : 0,
              marginTop:  stage > geo ? "5px": 0,
              border: stage > geo ? "1px rgba(0,0,0,0.1) solid" : "0px rgba(0,0,0,0.1) solid",
              boxSizing: "border-box"
            }}>
            <img src={map} style={{ width: "100%", borderRadius: "13px" }}/>
          </div>
          <div style={{ background: "rgba(0,0,100,0.3)", height: "20%", color: "white", marginTop: "2%", borderRadius: "20px" }}>
            <div className="fr" style={{ padding: "3%", justifyContent: "space-around"}}>
              <div className="fc ac">
                <div style={{ fontWeight: "bold", fontSize: "8pt" }}>
                  Income
                </div>
                <div style={{ fontWeight: "bold", fontSize: "9.5pt" }}>
                  $1,345.25
                </div>
              </div>
              <div className="fc ac">
                <div style={{ fontWeight: "bold", fontSize: "8pt" }}>
                  Spending
                </div>
                <div style={{ fontWeight: "bold", fontSize: "9.5pt" }}>
                  $643.99
                </div>
              </div>
            </div>

            <div style={{ background: "#f7f9fc", height: "1000px", color: "#999", padding: "10px", borderRadius: "20px" }}>
              <strong style={{ color: "#5a6be3", marginTop: "5px", fontSize: "13pt", display: "inline-block" }}>Transactions</strong>
              <div className="fc" style={{ marginTop: "10px" }}>
                {this.expenses()}
              </div>
            </div>
          </div>
        </div>

        <div style={{ background: "#f7f9fc", width: "100%", height: "10%", boxShadow: "0px 5px 12px #777", position: "absolute", bottom: "0"}}>
          <div className="fr" style={{ justifyContent: "space-around", padding: "6px 10px 10px 10px", fontSize: "9px" }}>
            <div className="fc ac">
              <img src={home} style={this.iconstyle} />
              <div>home</div>
            </div>
            <div className="fc ac" style={{ color: "#5298d3" }}>
              <img src={thunder} style={{ ...this.iconstyle, opacity: 0.9}} />
              <div>account</div>
            </div>
            <div className="fc ac">
              <img src={card}  style={this.iconstyle}/>
              <div>cards</div>
            </div>
            <div className="fc ac">
              <img src={menu}  style={this.iconstyle}/>
              <div>more</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  iconstyle = {
    maxHeight: "20px",
    maxWidth: "22px",
    opacity: 0.5,
    marginBottom: "2px"
  }

  render() {
    const { openRequest } = this.props;
    const { stage } = this.state;
    return (
      <div className="fc ac" style={{ width: "100%", marginTop: "20px", color: "white", margin: "auto"}}>
        <div className="fr" style={{ justifyContent: "center", gap: "100px", width: "100%", color: "#777", marginTop: "0px", flexWrap: "wrap" }}>

          <div className="fc jc" style={{ minWidth: "360px", zIndex: 100, marginBottom: "30px", color: "white", padding: "10px"  }}>
            <div style={{ minWidth: "360px", maxWidth: "475px" }}>
                <h1 id="tagline" style={{ margin: "0px 0px 15px 0px" }}>Universal Transaction Enrichment API</h1>
                <p style={{ fontWeight: "bold", fontSize: "17pt" }}>
                  Instantly upgrade your transaction data
                </p>
            </div>
            <div style={{ justifyItems: "left", display: "grid", gridTemplateColumns: "auto auto", fontSize: "17pt", gridGap: "15px" }}>
              <div>AI Cleaned Merchant Names</div>
              <img src="/check-mark-white.png" style={{...this.checkstyle, opacity: stage > cleaned ? 1 : 0}}/>
              <div>Intelligent Categorization</div>
              <img src="/check-mark-white.png" style={{...this.checkstyle, opacity: stage > categories ? 1 : 0}} />
              <div>High Definition Logos</div>
              <img src="/check-mark-white.png" style={{...this.checkstyle, opacity: stage > logos ? 1 : 0}} />
              <div>Recurring Payment Flagging</div>
              <img src="/check-mark-white.png" style={{...this.checkstyle, opacity: stage > recurring ? 1 : 0}} />
              <div>Granular Geo Locations</div>
              <img src="/check-mark-white.png" style={{...this.checkstyle, opacity: stage > geo ? 1 : 0}} />
            </div>
            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <BigButton
                content="Request API Access"
                onClick={openRequest}
                style={{ flexGrow: 1, marginTop: "1em", width: "auto" }}
              />
              <div style={{ opacity: "0.5", margin: "auto", fontSize: "10pt", marginTop: "5px" }}>
               Or reach out to us at <a target="_blank" style={{ textDecoration: "underline"}} href="mailto:contact@spade.dev">Contact@Spade.dev</a>
              </div>
            </div>
          </div>
          <div className="hide_when_small" style={{ height: "660px" }}>
            <div style={{ height: "500px", minWidth: "calc(500px / 2.02)", position: "relative", fontFamily: "Helvetica", transform: "scale(1.2) translateY(40px)" }} id="phonedemo">
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "96%",
                  height: "97%",
                  marginTop: "2%",
                  marginLeft: "2%",
                  borderRadius: "40px",
                  overflow: "hidden",
                  boxShadow: "5px 10px 5px #777"
                }}>
                {this.phoneScreen()}
              </div>
              <div style={{ height: "100%", position: "absolute", top: 0, left: 0 }}><img src={iphone} style={{ height: "100%"}} /></div>
            </div>
          </div>
        </div>

      </div>
    );
  }

  checkstyle = {
    width: "40px",
    opacity: 0,
    transition: "opacity 0.75s"
  }

}

export default UpgradeYourData;

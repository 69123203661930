import './General.css';
import './background.css';
import React from 'react';
import {Link, navigate} from "@reach/router"
import { useIdentityContext } from "react-netlify-identity";
import Wave from "../Components/Wave";
import Footer from "../Components/Footer";

function General(props){
  const { renderFooter=true, aboveWave=true, hideLogo=false } = props;
  const aboveWaveHeight = aboveWave ? "950px" : "340px";

  const { logoutUser, isConfirmedUser } = useIdentityContext()

    return (
      <div>
        <div className="upper fancybackground" style={{ height: aboveWaveHeight }}>
          <div className="home_container">
            <header
              style={{ minHeight: "100px", display: "flex", alignItems: "center", justifyContent: "space-between", margin: "0px 20px 25px 20px"}}
              className="column_when_small"
            >
              {!hideLogo ? (
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  <div style={{ display: "flex", alignItems: "center", height: "100px" }}>
                    <img src="/Spade_Icon_REB_rev.png" height="50px" style={{ marginRight: "20px" }} />
                    <img src="/Spade_Primary_RGB_rev.png" style={{ height: "35px" }}/>
                  </div>
                </Link>
              ): <div/> }

              <nav style={{ height: "100%", display: "flex", alignItems: "center", fontWeight: "700", fontSize: "11pt" }}>
                {/*<div style={{ marginRight: "50px" }}>*/}
                {/*  <a href="/docs">*/}
                {/*    V1 Docs*/}
                {/*  </a>*/}
                {/*</div>*/}
                <div>
                  {isConfirmedUser ? (
                      <Link to="/" onClick={logoutUser}>
                        Sign out
                      </Link>
                  ): (
                    <a href="/signin">
                      V1 Sign in
                    </a>
                  )}
                </div>
              </nav>
            </header>
            {props.children}
          </div>
        </div>
        {renderFooter && (
          <React.Fragment>
            <Wave>
              {props.customWave ? props.customWave : <Footer/>}
            </Wave>
          </React.Fragment>
        )}
      </div>
    );
}

export default General;

import React from 'react';
import PortalTab from "../PortalTab";
import { get_usage } from "../../../libs/api/endpoints";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Moment from 'moment';
import UsageGraph from "./UsageGraph";
import Statistic from "../../../Components/Statistic";

class Usage extends React.Component {


  state = {
    usage: [],
    loading: false,
  }

  componentDidMount() {
    this.setState({ loading: true });
    get_usage().then((response) => {
      const data = response.data.usage.map((r) => {
        const m = Moment(r.month)
        return {
          period: r.month,
          month: m.format('MMM'),
          name: m.format("MMM Y"),
          Enriched: Number(r.num_enriched),
          Logos: Number(r.num_logos),
          Locations: Number(r.num_locations),
        }
      })
      this.setState({ usage: data });
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      this.setState({ loading: false });
    })
  }

  // TODO chuck a graph on this bitch
  render() {
    const { usage = [], loading } = this.state;
    let totalEnrichments = 0;
    let totalLogos = 0;
    let totalLocations = 0;
    usage.forEach((row) => {
      totalEnrichments += Number(row.Enriched);
      totalLogos += Number(row.Logos);
      totalLocations += Number(row.Locations);
    });
    console.log(usage);
    const transactionsThisMonth = usage.length > 0 ? usage[0].Enriched : 0;
    return (
      <PortalTab
        title="API Usage"
        loading={loading} description="Monitor API Usage"
        right={(
          <div style={{ fontSize: "11pt", textAlign: "right" }}>
            <div>Total Enrichments: <b>{totalEnrichments}</b></div>
            <div>Total Matched Logos: <b>{totalLogos}</b></div>
            <div>Total Locations Found: <b>{totalLocations}</b></div>
          </div>
        )}
      >
        {/*<div className="fr" style={{ justifyContent: "space-around" }}>*/}
        {/*  <Statistic number={totalEnrichments} title="Total Enrichments" />*/}
        {/*  <Statistic number={transactionsThisMonth} title="Enrichments This Month" />*/}
        {/*  <Statistic number={totalLogos} title="Total Logos Found" />*/}
        {/*  <Statistic number={totalLogos} title="Total Locations Found" />*/}
        {/*</div>*/}
        <h4>12 Month History</h4>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-around", alignItems: "center" }}>
          <Paper style={{ width: "max-content", marginBottom: "1em" }}>
            <Table size="small" style={{ width: "max-content" }}>
              <TableHead >
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell>Transactions</TableCell>
                  <TableCell>Logos Found</TableCell>
                  <TableCell>Locations Found</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usage.map((row) => {
                  return (
                    (
                      <TableRow key={row.period}>
                        <TableCell align="right">{row.name}</TableCell>
                        <TableCell align="right">{row.Enriched.toLocaleString()}</TableCell>
                        <TableCell align="right">{row.Logos.toLocaleString()}</TableCell>
                        <TableCell align="right">{row.Locations.toLocaleString()}</TableCell>
                      </TableRow>
                    )
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
          <UsageGraph usage={usage} />
        </div>
      </PortalTab>
    );
  }
}

export default Usage;

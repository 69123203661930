import General from "./General";
import React from 'react';
import { Link } from "@reach/router";


class Success extends React.Component {

  render() {
    return (
      <General>
        <main style={{
          display: "flex",
          margin: "auto",
          position: "relative",
          zIndex: 10,
          minHeight: "500px",
          flexDirection: "column",
          width: "max-content"
        }}>
          <div className="fc jc ac" style={{ marginBottom: "250px" }}>
            <h1>Sign Up Complete</h1>
            <div>
              Please Confirm your Email Address
            </div>
            <Link to="/" style={{ marginTop: "30px" }}>
              <img src="/logo_mini.png" height="50px" />
            </Link>
            <Link to="/signin" style={{ color: "white", marginTop: "1em", fontSize: "10pt" }}>Sign In</Link>
          </div>
        </main>
      </General>
    );
  }
}

export default Success;

import React, {useState} from 'react';
import {CircularProgress, Dialog, Snackbar} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import TextBox from "../../Components/TextBox";
import SpadeLogo from "../../Components/SpadeLogo";
import BigButton from "../../Components/BigButton";
import {Link, navigate} from "@reach/router";
import { send_access_request } from "../../libs/api/endpoints";
import axios from "axios";
import { buildEndpoint } from "../../libs/api/fe_requests";

function RequestAccessDialog(props){
  const [ email, setEmail ] = useState();
  const [ website, setWebsite ] = useState("");
  const [ position, setPosition ] = useState("");
  const [ description, setDescription ] = useState("");
  const [ name, setName ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const [ success, setSuccess ] = useState(false);

  const validateEmail = (email) => {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

  const signUp = () => {
    if (name.trim().length < 1 || website.trim().length < 1 || email.trim().length < 1 || description.trim().length < 1|| position.trim().length < 1) {
      return setError("All fields are required");
    }

    if (!validateEmail(email)) {
      return setError("Invalid Email");
    }

    // Lazy encourage business email approach
    const blacklist = ["gmail", "hotmail", "outlook", "icloud", "ymail" ]
    for (let i = 0; i < blacklist.length; i += 1) {
      if (email.indexOf(blacklist[i]) > -1) {
        return setError("Please use a business email address")
      }
    }

    setLoading(true);
    setError();


    axios({
      method: 'post',
      url: buildEndpoint('/requestaccess'),
      data: {name, position, website, email, description},
    })
    .then((resp) => {
      setSuccess(true)
    })
    .catch((err) => {
      console.error(err)
      setError("Something went wrong! Please email us for access!")
    })
    .finally(() => {
      setLoading(false);
    })

  }

    return (
      <Dialog open={true} onClose={props.onClose} >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "450px", padding: "2em 1em 3em 1em", maxWidth: "80vw" }}>
          <SpadeLogo size="50px" />

          {success ? (
            <div className="fc ac">
              <h2 className="fancytext">Request Submitted!</h2>
              <div style={{ padding: "1em", textAlign: "center"}}>
                Thank you for requesting API access
                <br/>We will contact you shortly with credentials</div>
              <br/>
              {/*<div>*/}
              {/*  Check out our <a className="fancytext" href="/docs">docs</a> or <a className="fancytext" href="/quickstart">quickstart</a> guide*/}
              {/*</div>*/}
            </div>
          ) : (
            <React.Fragment>
              <h2 className="fancytext" style={{ zIndex: 100 }} >Request API Access</h2>
              <TextBox onEnter={signUp} disabled={loading} label="Full Name" placeholder="Name" onChange={(e) => { setName(e.target.value); }}/>
              <TextBox onEnter={signUp} disabled={loading} label="Position" placeholder="Engineer, CTO, CEO, etc." onChange={(e) => { setPosition(e.target.value); }}/>
              <TextBox onEnter={signUp} disabled={loading} label="Company Website" placeholder="Website" onChange={(e) => { setWebsite(e.target.value); }}/>
              <TextBox onEnter={signUp} disabled={loading} label="Business Email Address" placeholder="Business Email Address" type="email" onChange={(e) => { setEmail(e.target.value); }}/>
              <TextBox
                multiline={true}
                disabled={loading}
                label="Product Description"
                placeholder="Tell us what you're building"
                onChange={(e) => { setDescription(e.target.value); }}
                style={{
                  height: "auto",
                  width: "70%"
                }}
              />
              {loading ? (
                <CircularProgress color="white" style={{ marginTop: "0.5em"}}/>
              ) : (
                <BigButton content="Request" onClick={signUp}/>
              )}

              <span style={{ color: "grey", marginTop: "2em", fontSize: "10pt" }}>We'll review your request as soon as possible</span>

            </React.Fragment>
          )}
        </div>
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={error} autoHideDuration={4000} onClose={() => { setError() }} >
          <MuiAlert severity="error" variant="filled">{error}</MuiAlert>
        </Snackbar>
      </Dialog>
    );
}

export default RequestAccessDialog;

import React, {useState} from 'react';
import General from "../General";
import TextBox from "../../Components/TextBox";
import BigButton from "../../Components/BigButton";
import {Link, navigate} from "@reach/router";
import { useIdentityContext, User } from "react-netlify-identity"
import { CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function SignUp(){
  const [ email, setEmail ] = useState("")
  const [ password, setPassword ] = useState("")
  const [ error, setError ] = useState()
  const [ loading, setLoading ] = useState(false)

  const { loginUser } = useIdentityContext()

  const signIn = () => {
    if (email.length < 1) {
      return setError("Email is Missing");
    }
    if (!validateEmail(email)) {
      return setError("Invalid Email");
    }
    if (password.length < 1) {
      return setError("Password is Missing");
    }

    setLoading(true);
    setError();
    loginUser(email, password, true)
      .then((user) => {
        navigate("/");
      })
      .catch((err, r) => {
        const message = err.message.replace("invalid_grant:","")
        setError(message)
      })
      .finally(() => { setLoading(false) })
  }

  const validateEmail = (email) => {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

  return (
    <General>
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
          position: "relative",
          zIndex: 10,
          width: "100%",
          boxSizing: "border-box",
          marginTop: "70px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "450px" }}>
          <h1>Sign In</h1>
          <TextBox
            disabled={loading}
            label="Email Address"
            placeholder="Email Address"
            type="email"
            onChange={(e) => { setEmail(e.target.value); }}
            onEnter={signIn}
          />
          <TextBox
            disabled={loading}
            label="Password"
            placeholder="Password"
            type="password"
            onChange={(e) => { setPassword(e.target.value); }}
            onEnter={signIn}
          />
          {loading ? (
            <CircularProgress color="white" style={{ marginTop: "0.5em"}}/>
          ) : (
            <BigButton content="Sign in" onClick={signIn}/>
          )}
          <div style={{ marginTop: "1.5em", fontSize: "10pt", opacity: "0.6"}}>
            <Link to="/recover" style={{ textDecoration: "underline"}}>
              Forgot Password?
            </Link>
          </div>
          {/*<div style={{ marginTop: "0.5em", fontSize: "10pt", opacity: "0.6"}}>*/}
          {/*  <Link to="/signup" style={{ textDecoration: "underline"}}>*/}
          {/*    Register*/}
          {/*  </Link>*/}
          {/*</div>*/}

        </div>
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={error} autoHideDuration={4000} onClose={() => { setError() }} >
          <MuiAlert severity="error" variant="filled">{error}</MuiAlert>
        </Snackbar>
      </main>
    </General>
  );
}

export default SignUp;

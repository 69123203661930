import {Button, TextField} from "@material-ui/core";
import React from "react";

class BigButton extends React.Component {

  render(){
    const { style={}, content, onClick=()=>{}, disabled=false } = this.props;
    return (
      <Button
        type="submit"
        variant="contained"
        onClick={onClick}
        disabled={disabled}
        style={{
          borderRadius: "100px",
          color: disabled ? "slategrey" : "#5f4dee",
          fontWeight: "bold",
          padding: "1.2em 3em 1em 3em",
          whiteSpace: "nowrap",
          marginTop: "1em",
          width: "max-content",
          ...style,
        }}
      >
        <span className="fancytext">{content}</span>
      </Button>
    )
  }

}

export default BigButton;

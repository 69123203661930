import React from "react";

class Statistic extends React.Component {

  render(){
    const { title, number, style={}, dollars } = this.props;
    return (
      <div className="fc ac jc" style={{ margin: "0.5em 1em" , ...style}}>
        <div style={{ fontWeight: "bold", fontSize: "8pt", color: "#555" }}>
          {title}
        </div>
        <div style={{ fontWeight: "bold", fontSize: "24pt" }}>
          {dollars && "$"}
          {Number(number).toLocaleString()}
        </div>
      </div>
    )
  }

}

export default Statistic;
import React from "react";

class SpadeLogo extends React.Component {

  render(){
    const { size="50px", style={} } = this.props;
    return (
        <div className="fancybackground"
          style={{
            width: size,
            height: size,
            maskImage: "url(/Spade_Icon_REB_small.png)",
            WebkitMaskImage: "url(/Spade_Icon_REB_small.png)",
            maskSize: size,
            WebkitMaskSize: size,
            ...style
          }}
        />
    )
  }

}

export default SpadeLogo;

import React from 'react';
import General from "../General";
import {  Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import RequestAccessDialog from "../SignIn/RequestAccessDialog";
import TrustedBy from "./TrustedBy";
import Footer from "../../Components/Footer";
import UpgradeYourData from "./UpgradeYourData";
import ExampleEnrichments from "../../Components/ExampleEnrichments";
import SpadeLogo from "../../Components/SpadeLogo";
import Applications from "../../Components/Applications";
import BigButton from "../../Components/BigButton";
import {Spa} from "@material-ui/icons";

class Home extends React.Component{

  state = {
    emailError: false,
    requestDialog: false,
  }

  handleClose = () => {
    this.setState({requestDialog: false})
  }

  waveContent = () => {
    return (
      <div style={{ color: "rgb(90, 107, 227)" }}>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
          <Applications/>
          <div style={{ margin: "30px"}}>
            <SpadeLogo size="50px" />
          </div>
          {/*<BuildFaster />*/}
          <div className="fr" style={{ justifyContent: "center", alignItems: "center", width: "100%", gap: "30px" }} >
            <ExampleEnrichments />
            <div>
              <div className="fc" style={{ alignItems: "center", maxWidth: "450px", marginTop: "10px", color: "#555", padding: "10px"}}>
                <h1 className="fancytext" style={{ fontSize: "30pt", marginBottom: "0em", textAlign: "center" }}>Developer Focused</h1>
                <div style={{ fontSize: "14pt", fontWeight: "bold" }}>
                  Integrate in hours, not weeks
                </div>
                <span  style={{ fontSize: "17pt" }}>
                  <p>
                    <b>Stop wasting developer hours</b> cleaning and structuring data. Instantly receive human and machine readable data with a single API call.
                  </p>
                  <p>
                    With our platform agnostic approach, we work out of the box with no configuration.
                  </p>
                </span>
              </div>
            </div>
          </div>
          <div className="hide_when_small" style={{ height: "110px" }}/>
          <div className="fc" style={{ margin: "80px 0px 80px 0px", alignItems: "center" }}>
            <SpadeLogo size="50px" style={{ margin: "auto" }}/>
            <h2 className="fancytext">Ready to get started?</h2>
            <BigButton
              content="Request API Access"
              onClick={(e) => {
                this.setState({ requestDialog: true })
              }}
              style={{ flexGrow: 1, marginTop: "1em", width: "auto" }}
            />
            <div style={{ margin: "1.5em auto", fontSize: "12pt", fontWeight: "bold" }}>
              Or reach out to us at <a target="_blank" style={{ textDecoration: "underline", color: "inherit" }} href="mailto:contact@spade.dev">Contact@Spade.dev</a>
            </div>
          </div>
          <TrustedBy />
        </div>
        <Footer />
        <div style={{ color: "#888", fontSize: "10pt", margin: "auto", marginTop: "10px", width: "max-content" }}>
          <div>Some graphics by&nbsp;
            <a style={{ color: "#5b64e6" }} href="https://www.freepik.com" title="Freepik">Freepik</a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { emailError, example, requestDialog } = this.state;
    // const ex = examples[example % examples.length];
    return (
      <General customWave={this.waveContent()}>
        <main
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            alignItems: "center",
            position: "relative",
            zIndex: 10,
            width: "100%",
            boxSizing: "border-box"
          }}>
          <div id="main_info" style={{ width: "100%"}}>
            <UpgradeYourData
              openRequest={(e) => {
                this.setState({ requestDialog: true })
              }}/>
          </div>

        </main>
        <Snackbar
          open={emailError}
          autoHideDuration={3000}
          onClose={() => { this.setState({ emailError: false })}}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MuiAlert severity="error" elevation={100} variant="filled">Invalid Email Address</MuiAlert>
        </Snackbar>
        {requestDialog && <RequestAccessDialog onClose={this.handleClose} />}

      </General>
    );
  }

}

export default Home;

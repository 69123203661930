import React from 'react';
import General from "../General";
import Footer from "../../Components/Footer";

class Privacy extends React.Component{

  waveContent = () => {
    return (
      <main style={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <div style={{ maxWidth: "1000px", width: "100%", padding: "0.5em" }}>
          <p
            id="docs-internal-guid-47f55979-7fff-2b15-fd1d-ff15b1185e27"
            style={{
              lineHeight: "1.295",
              textAlign: "center",
              marginTop: "0pt",
              marginBottom: "0pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Spade Data Inc.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "center",
              marginTop: "0pt",
              marginBottom: "0pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Privacy Policy
    </span>
          </p>
          <p>&nbsp;</p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Last Updated
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      : Aug 29th 2022
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >

    </span>


            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >

    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Spade Data Inc. (“
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      Spade
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      ,” “
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      we
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      ,” “
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      our
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      ,” and/or “
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      us
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      ”) values the privacy of individuals who use our website, including{" "}
    </span>
            <a style={{ textDecoration: "none" }} href="https://www.spade.com">
      <span
        style={{
          fontSize: "11pt",
          fontFamily: "Arial",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        https://www.spade.com
      </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      {" "}
              and{" "}
    </span>
            <a style={{ textDecoration: "none" }} href="https://www.spade.dev">
      <span
        style={{
          fontSize: "11pt",
          fontFamily: "Arial",
          color: "#1155cc",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        https://www.spade.dev
      </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      {" "}
              (our “
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      Website
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      ”).&nbsp; This privacy policy (the “
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      Privacy Policy
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      ”) explains how we collect, use, and share personal information from users
      of our Website (“
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      you
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      ” or “
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      your
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      ”).&nbsp; By using our Website, you agree to the collection, use,
      disclosure, and procedures described in this Privacy Policy.&nbsp; Beyond
      the Privacy Policy, your use of our Website is also subject to our <a href="/terms">Terms
              of Service.</a>
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Personal Information We Collect
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We may collect a variety of personal information from or about you or your
      devices from various sources, as described below.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      A. Information You Provide to Us
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Request API Access
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      &nbsp;{" "}
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      When you request API access, we may ask you for your name, position,
      company website, email address, and other information.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Dashboard
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; When you use the dashboard via the Website, we may ask you for
      your email address and other information.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Communications
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; If you contact us directly, we may receive personal information
      about you.&nbsp; For example, when you contact us for more information
      about Spade, we may receive your name, email address, the contents of a
      message or attachments that you may send to us,&nbsp; and other
      information you choose to provide.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Careers
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; If you decide that you wish to apply for a job with us, you may
      submit your contact information and your resume online.&nbsp; We will
      collect the information you choose to provide on your resume, such as your
      education and employment experience.&nbsp; You may also apply through
      LinkedIn. If you do so, we will collect the information you make available
      to us on LinkedIn.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      B. Information We Collect When You Use Our Website
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Device Information
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; We receive information about the device and software you use to
      access our Website, including IP address, web browser type, and operating
      system version.&nbsp;
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Usage Information
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; To help us understand how you use our Website and to help us
      improve it, we automatically receive information about your interactions
      with our Website like the pages or other content you view and the dates
      and times of your visits.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Information from Cookies and Similar Technologies
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; We and our third-party partners collect personal information using
      cookies, pixel tags, or similar technologies.{" "}
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      &nbsp;Our third-party partners, such as analytics and advertising
      partners, may use these technologies to collect information about your
      online activities over time and across different services.&nbsp;{" "}
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      Cookies are small text files containing a string of alphanumeric
      characters.&nbsp; We may use both session cookies and persistent
      cookies.&nbsp; A session cookie disappears after you close your
      browser.&nbsp; A persistent cookie remains after you close your browser
      and may be used by your browser on subsequent visits to our Website.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Please review your web browser’s “Help” file to learn the proper way to
      modify your cookie settings.&nbsp; Please note that if you delete or
      choose not to accept cookies from our Website, you may not be able to
      utilize the features of our Website to their fullest potential.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      How We Use the Information We Collect
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We use the information we collect:
    </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              dir="ltr"
            >
              <p
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
                dir="ltr"
              >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          To provide, maintain, improve, and enhance our Website;
        </span>
              </p>
            </li>
            <li
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              dir="ltr"
            >
              <p
                style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "0pt" }}
                dir="ltr"
              >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          To understand and analyze how you use our Website and develop new
          products, services, features, and functionality;
        </span>
              </p>
            </li>
            <li
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              dir="ltr"
            >
              <p
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
                dir="ltr"
              >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          To communicate with you, provide you with updates and other
          information relating to our Website, provide information that you
          request, respond to comments and questions, and otherwise provide
          customer support;
        </span>
              </p>
            </li>
            <li
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              dir="ltr"
            >
              <p
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
                dir="ltr"
              >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          For marketing and advertising purposes, such as developing and
          providing promotional and advertising materials that may be relevant,
          valuable, or otherwise of interest to you;
        </span>
              </p>
            </li>
            <li
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              dir="ltr"
            >
              <p
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
                dir="ltr"
              >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          To find and prevent fraud, and respond to trust and safety issues that
          may arise;&nbsp;
        </span>
              </p>
            </li>
            <li
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              dir="ltr"
            >
              <p
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
                dir="ltr"
              >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          For compliance purposes, including enforcing our Terms of Service or
          other legal rights, or as may be required by applicable laws and
          regulations or requested by any judicial process or governmental
          agency; and
        </span>
              </p>
            </li>
            <li
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              dir="ltr"
            >
              <p
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "8pt"
                }}
                dir="ltr"
              >
        <span
          style={{
            fontSize: "11pt",
            fontFamily: "Arial",
            color: "#000000",
            backgroundColor: "transparent",
            fontWeight: 400,
            fontStyle: "normal",
            fontVariant: "normal",
            textDecoration: "none",
            verticalAlign: "baseline",
            whiteSpace: "pre-wrap"
          }}
        >
          For other purposes for which we provide notice at the time the
          personal information is collected.
        </span>
              </p>
            </li>
          </ul>
          <p
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      How We Share the Information We Collect
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Partners and Affiliates
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; We may share any information we receive with our partners,
      corporate affiliates, parents, or subsidiaries for any purpose described
      in this Privacy Policy.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Vendors and Service Providers
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; We may share any information we receive with vendors and service
      providers retained in connection with the provision of our Website.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Analytics Partners
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; We use analytics services such as Google Analytics to collect and
      process certain analytics data.&nbsp; These services may also collect
      information about your use of other websites, apps, and online
      resources.&nbsp; You can learn more about Google’s practices by visiting
    </span>
            <a
              style={{ textDecoration: "none" }}
              href="https://www.google.com/policies/privacy/partners/"
            >
      <span
        style={{
          fontSize: "11pt",
          fontFamily: "Arial",
          color: "#1155cc",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        https://www.google.com/policies/privacy/partners/
      </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; To help us understand how you use our Website and to help us
      improve it, we automatically receive information about your interactions
      with our Website, like the pages or other content you view and the dates
      and times of your visits.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      As Required by Law and Similar Disclosures
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; We may access, preserve, and disclose your personal information if
      we believe doing so is required or appropriate to: (a) comply with law
      enforcement requests and legal process, such as a court order or subpoena;
      (b) respond to your requests; or (c) protect your, our, or others’ rights,
      property, or safety.&nbsp; For the avoidance of doubt, the disclosure of
      your personal information may occur if you post any objectionable content
      on or through our Website.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Merger, Sale, or Other Asset Transfers
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      &nbsp;We may transfer or disclose your personal information to service
      providers, advisors, potential transactional partners, or other third
      parties in connection with the consideration, negotiation, or completion
      of a corporate transaction in which we are acquired by or merged with
      another company, or we sell, liquidate, or transfer all or a portion of
      our assets.&nbsp;
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Consent
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp; We may also disclose personal information from or about you or
      your devices with your permission.
    </span>
          </p>
          <p
            style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Your Choices
    </span>
          </p>
          <p
            style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Marketing Communications.{" "}
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      &nbsp;You can unsubscribe from our promotional emails via the link
      provided in the emails. Even if you opt out of receiving promotional
      messages from us, you will continue to receive administrative messages
      from us.
    </span>
          </p>
          <p
            style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Do Not Track.&nbsp;{" "}
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      There is no accepted standard on how to respond to Do Not Track signals,
      and we do not respond to such signals.
    </span>
          </p>
          <p
            style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Third Parties
    </span>
          </p>
          <p
            style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Our Website may contain links to other websites, products, or services
      that we do not own or operate.&nbsp; We are not responsible for the
      privacy practices of these third parties.&nbsp; Please be aware that this
      Privacy Policy does not apply to your activities on these third-party
      services or any information you disclose to these third parties.&nbsp; We
      encourage you to read their privacy policies before providing any
      information to them.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Security&nbsp;
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We make reasonable efforts to protect your personal information by using
      physical and electronic safeguards designed to improve the security of the
      personal information we maintain.&nbsp; However, as no electronic
      transmission or storage of information can be entirely secure, we can make
      no guarantees as to the security or privacy of your personal information.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Children’s Privacy
    </span>
          </p>
          <h1
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "0pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We do not knowingly collect, maintain, or use personal information from
      children under 13 years of age, and no parts of our Website are directed
      to children.&nbsp; If you learn that a child has provided us with personal
      information in violation of this Privacy Policy, you may alert us at{" "}
    </span>
            <a style={{ textDecoration: "none" }} href="mailto:security@spade.com">
      <span
        style={{
          fontSize: "11pt",
          fontFamily: "Arial",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        security@spade.com
      </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      .&nbsp;
    </span>
          </h1>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "12pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      International Visitors
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Our Website is hosted in the United States (“
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: "bold",
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      U.S.
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      ”) and intended for visitors located within the U.S.&nbsp; If you choose
      to use our Website from the European Union or other regions of the world
      with laws governing data collection and use that may differ from U.S. law,
      then please note that you are transferring your personal information
      outside of those regions to the U.S. for storage and processing.&nbsp;
              Also, we may transfer your personal information from the U.S. to other
      countries or regions in connection with storage and processing of data,
      fulfilling your requests, and operating our Website.&nbsp; By providing
      any information, including personal information, on or through our
      Website, you consent to such transfer, storage, and processing.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Changes to This Privacy Policy
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "#ffffff",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      We will post any adjustments to the Privacy Policy on this page, and the
      revised version will be effective when it is posted.
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      &nbsp;{" "}
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "#ffffff",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      If we materially change the ways in which we use or share personal
      information previously collected from you through our Website, we will
      notify you through our Website, by email, or other communication.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: "bold",
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "underline",
        WebkitTextDecorationSkip: "none",
        textDecorationSkipInk: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Contact Information
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      If you have any questions, comments, or concerns about our processing
      activities, please email us at{" "}
    </span>
            <a style={{ textDecoration: "none" }} href="mailto:security@spade.com">
      <span
        style={{
          fontSize: "11pt",
          fontFamily: "Arial",
          color: "#0563c1",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "underline",
          WebkitTextDecorationSkip: "none",
          textDecorationSkipInk: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre-wrap"
        }}
      >
        security@spade.com
      </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      {" "}
              or write to us at:
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      Spade Data Inc.
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      125 West 25
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      <span style={{ fontSize: "0.6em", verticalAlign: "super" }}>th</span>
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      {" "}
              Street, 2
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      <span style={{ fontSize: "0.6em", verticalAlign: "super" }}>nd</span>
    </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Arial",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
      {" "}
              Floor
    </span>
          </p>
          <p
            style={{
              lineHeight: "1.295",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt"
            }}
            dir="ltr"
          >
    <span
      style={{
        fontSize: "11pt",
        fontFamily: "Arial",
        color: "#000000",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontStyle: "normal",
        fontVariant: "normal",
        textDecoration: "none",
        verticalAlign: "baseline",
        whiteSpace: "pre-wrap"
      }}
    >
      New York, NY 10001
    </span>
          </p>
      </div>

      </main>
    );
  }

  render() {
    return (
      <React.Fragment>
        <General customWave={this.waveContent()} aboveWave={false} />
        <Footer />
      </React.Fragment>

    );
  }

}

export default Privacy;

import React from "react";

class FancyImage extends React.Component {

  render(){
    const { width, height, style={}, src } = this.props;
    return (
        <div className="fancybackground"
          style={{
            width: width,
            height: height,
            maskImage: `url(${src})`,
            WebkitMaskImage: `url(${src})`,
            maskSize: `${width} ${height}`,
            WebkitMaskSize: `${width} ${height}`,
            ...style
          }}
        />
    )
  }

}

export default FancyImage;

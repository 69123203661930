import React from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import {
  Circle as CircleStyle,
  Fill,
  Stroke,
  Style,
  Text,
} from 'ol/style';
import { OSM, Vector as VectorSource, Cluster } from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import Overlay from 'ol/Overlay';
import Paper from "@material-ui/core/Paper";
import Moment from 'moment';

const clusterStyle = (feature) => {
  let size = feature.get('features').length;
  return  new Style({
    image: new CircleStyle({
      radius: 14,
      stroke: new Stroke({ color: '#fff', width: 2 }),
      fill: new Fill({ color: 'rgba(85,77,239,0.8)' }),
    }),
    text: new Text({
      text: size.toString(),
      textAlign: "center",
      offsetY: 1,
      font: 'bold 8pt opensans, sans-serif',
      fill: new Fill({ color: '#fff' }),
    }),
  });
}

const tableStyle = {
  border: "1px solid #ddd",
  borderCollapse: "collapse",
  padding: "2px 5px",
  whiteSpace: "nowrap",
}

class SpadeMap extends React.Component {

  state = {
    popupFeatures: [],
  }

  mapClicked = (event, component) => {
    document.getElementById("scrollable_map_table").scrollTop = 0;
    component.vectorLayer.getFeatures(event.pixel).then(function (features) {
      let feature = features.length ? features[0] : undefined;
      if (feature) {
        component.setState({
          popupFeatures: Array.from(new Set(feature.values_.features.map((f) => { return f.values_.merchant; })))
        })
        component.overlay.setPosition(event.coordinate);
      } else {
        component.overlay.setPosition(undefined);
      }
    })

  }

  componentDidMount() {
    //Create Overlay
    this.overlay = new Overlay({
      element: document.getElementById('map_popup'),
      autoPan: true,
      autoPanAnimation: {
        duration: 500,
      },
    });
    // Create Source
    this.vectorSource = new VectorSource({
      features: []
    })
    // Clustering
    const clusterSource = new Cluster({
      source: this.vectorSource,
      distance: 50,
    });
    // Create Layers
    const tiles = new TileLayer({ source: new OSM() });
    this.vectorLayer = new VectorLayer({
      source: clusterSource,
      style: clusterStyle,
    });
    // Create Map
    this.map = new Map({
      layers: [tiles, this.vectorLayer],
      overlays: [this.overlay],
      view: new View({
        center: fromLonLat([-97, 39]),
        zoom: 4.5
      }),
      target: 'map_base'
    });
    // listeners
    this.onClickListener = this.map.on('click', (event) => { this.mapClicked(event, this); });
    // this.onZoomListener = this.map.getView().on('change:resolution', (e) => {
    //   this.overlay.setPosition(undefined);
    // });
    this.update();
  }

  componentWillUnmount() {
    this.map.un('click', this.onClickListener);
    // this.map.un('propertychange', this.onZoomListener);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.update();
  }

  update = () => {
    const { locations = [] } = this.props;
    const features = locations.map((l) => {
      return new Feature({
        geometry: new Point(fromLonLat([l.long, l.lat])),
        merchant: l,
        id: l.name + l.lat + l.long,
      })
    })
    // TODO I Dont think clearing then adding is the best..
    this.vectorSource.clear();
    this.vectorSource.addFeatures(features);
  }

  render() {
    const { popupFeatures } = this.state;
    const { style={} } = this.props
    return (
      <div style={style}>
        <Paper
          elevation={3}
          id="map_base"
          style={{ filter: "saturate(1.3)", width: "100%", height: "100%", overflow: "hidden", backgroundColor: "#dedede" }}
        />
        <div
          id="map_popup"
        >
          <Paper
            elevation={3}
            style={{
              background: "white",
              color: "#777",
              fontSize: "10pt",
              position: "relative",
              transform: "translate(10px, 10px)",
              overflow: "hidden",
            }}
            // onWheel={(e) => {
            //   e.persist();
            //   e.stopPropagation()
            //   e.cancelBubble = true;
            //   // this.overlay.setPosition(undefined);
            // }}
          >
            <div
              style={{ maxHeight: "200px", overflowY: "scroll" }}
              id="scrollable_map_table"
            >
              <table style={tableStyle}>
                <tr style={{ fontSize: "9pt", ...tableStyle }}>
                  <th style={tableStyle}>Merchant</th>
                  <th style={tableStyle}>Transactions</th>
                  <th style={tableStyle}>Average Spent</th>
                  <th style={tableStyle}>Total Spent</th>
                </tr>
                {popupFeatures.sort((a,b) => { return b.count - a.count; }).map((merchant) => {
                  return (
                    <tr style={tableStyle} key={merchant.name+merchant.lat+merchant.long}>
                      <td style={tableStyle}>{merchant.name}</td>
                      <td style={{ textAlign: "right", ...tableStyle }}>{merchant.count}</td>
                      <td style={{ textAlign: "right", ...tableStyle }}>${merchant.average_spent || 0}</td>
                      <td style={{ textAlign: "right", ...tableStyle }}>${merchant.total_spent || 0}</td>
                    </tr>
                  )
                })}
              </table>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default SpadeMap;

import React from 'react';
import 'react-json-pretty/themes/monikai.css';
import Paper from "@material-ui/core/Paper";
import General from "../General";
import BigButton from "../../Components/BigButton";
import TextBox from "../../Components/TextBox";
import {CircularProgress, Snackbar} from "@material-ui/core";
import {demo_enrich} from "../../libs/api/endpoints";
import MuiAlert from "@material-ui/lab/Alert";


class DemoPage extends React.Component {

  state = {
    loading: false,
    mname: null,
    tname: null,
    mcc: null,
    plaidCode: null,
    address: null,
    city: null,
    state: null,
    zip: null,
    result: null,
    error: null,
  }

  enrich = () => {
    const { mname, tname, mcc, plaidCode, address, city, state, zip } = this.state;
    this.setState({ loading: true, error: null });

    const payload = {
      merchant_name: mname,
      transaction_name: tname,
      mcc: mcc,
      plaid_category_id: plaidCode,
      location: {
        address: address,
        city: city,
        state: state,
        zip_code: zip
      }
    }


    demo_enrich(payload)
      .then((response) => {
        this.setState({ result: response.data })
      })
      .catch((err) => {
        console.log(err)
        this.setState({ error: JSON.stringify(err?.response?.data, null,1) })
      })
      .finally(() => {
        this.setState({ loading: false });
      })
  }

  render() {
    const { loading, mname, tname, mcc, plaidCode, address, city, state, zip, error, result } = this.state;
    return (
      <General>
        <div style={{ display: "flex", justifyContent: "space-around", zIndex: 9999, position: "relative" }}>
          <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
            <h2>Input:</h2>
            <TextBox value={mname} onChange={(e) => { this.setState({ mname: e.target.value })}} disabled={loading} label="Merchant Name" name="Merchant Name"/>
            <TextBox value={tname} onChange={(e) => { this.setState({ tname: e.target.value })}} disabled={loading} label="Transaction Name" name="Transaction Name"/>
            <div style={{ width: "300px"}}>
              <TextBox value={mcc} onChange={(e) => { this.setState({ mcc: e.target.value })}} disabled={loading} label="MCC" name="MCC"/>
              <TextBox value={plaidCode} onChange={(e) => { this.setState({ plaidCode: e.target.value })}} disabled={loading} label="Plaid Category Code"/>
              <TextBox value={address} onChange={(e) => { this.setState({ address: e.target.value })}} disabled={loading} label="Address"/>
              <TextBox value={city} onChange={(e) => { this.setState({ city: e.target.value })}} disabled={loading} label="City"/>
              <TextBox value={state} onChange={(e) => { this.setState({ state: e.target.value })}} disabled={loading} label="State"/>
              <TextBox value={zip} onChange={(e) => { this.setState({ zip: e.target.value })}} disabled={loading} label="Zip"/>
            </div>
            <div style={{ margin: "auto", marginTop: "1em" }}>
              {loading ? (
                <CircularProgress color="white" style={{ marginTop: "0.5em"}}/>
              ) : (
                <BigButton content="Enrich" style={{ width: "300px" }} onClick={this.enrich} />
              )}
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
            <h2>Output:</h2>
            {result && (
              <Paper elevation={2} style={{ padding: "1.5em", color: "#555", display: "flex", flexDirection: "column", borderRadius: "15px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end" }}>
                  <div style={{ fontWeight: "bold", fontSize: "25pt"}}>
                    {result.normalized_merchant_name}
                  </div>

                  {result.logo && (
                    <div style={{  }}>
                      <img src={`http://v1.spadeapi.com${result.logo.path}?size=s`} style={{ maxWidth: "150px", maxHeight: "150px"}} />
                    </div>
                  )}
                </div>


                {result.merchant_info.website && (
                  <div style={{ margin: "0.25em 0px"}}>
                    {result.merchant_info.website}
                  </div>
                )}

                {result.via && (
                  <div style={{ margin: "0.25em 0px"}}><b>Via: </b>{result.via}</div>
                )}

                <div style={{ margin: "0.25em 0px"}}><strong>Recurring Payment: </strong> {result.is_recurring ? "True" : "False"}</div>

                {result.category && (
                  <div>
                    <strong>Categories:</strong>
                    <ol style={{ margin: "0.25em 0px" }}>
                      {result.category.map((c) => { return <li>{c}</li>})}
                    </ol>
                  </div>
                )}

                <table>
                  {result.location.address && <tr><th align="left">Address</th><td>{result.location.address}</td></tr>}
                  {result.location.city && <tr><th align="left">City</th><td>{result.location.city}</td></tr>}
                  {result.location.state && <tr><th align="left">State</th><td>{result.location.state}</td></tr>}
                  {result.location.zip_code && <tr><th align="left">Zip</th><td>{result.location.zip_code}</td></tr>}
                  {result.location.latitude && <tr><th align="left">Latitude</th><td>{result.location.latitude}</td></tr>}
                  {result.location.longitude && <tr><th align="left">Longitude</th><td>{result.location.longitude}</td></tr>}
                </table>
              </Paper>
            )}
          </div>
        </div>
        {/*<TextBox disabled={loading} label="API Key" name="APIKey" type="password"/>*/}
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "center" }} open={error} autoHideDuration={10000} onClose={() => { this.setState({ error: false }) }} >
          <MuiAlert severity="error" variant="filled">{error}</MuiAlert>
        </Snackbar>
      </General>
    );
  }
}

export default DemoPage;

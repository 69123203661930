import React, {useState} from 'react';
import General from "../General";
import TextBox from "../../Components/TextBox";
import BigButton from "../../Components/BigButton";
import {Link, navigate} from "@reach/router";
import { useIdentityContext } from "react-netlify-identity"
import { CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function ForgotPassword(){
  const [ email, setEmail ] = useState("")
  const [ error, setError ] = useState()
  const [ loading, setLoading ] = useState(false)
  const [ requested, setRequested ] = useState(false)

  const { requestPasswordRecovery } = useIdentityContext()

  const recover = () => {
    if (email.length < 1) {
      return setError("Email is Missing");
    }
    if (!validateEmail(email)) {
      return setError("Invalid Email");
    }
    setLoading(true);
    requestPasswordRecovery(email)
      .then((user) => {
        setRequested(true)
      })
      .catch((err, r) => {
        const message = err.message.replace("invalid_grant:","")
        setError(message)
      })
      .finally(() => { setLoading(false) })
  }

  const validateEmail = (email) => {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

  return (
    <General>
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
          position: "relative",
          zIndex: 10,
          width: "100%",
          boxSizing: "border-box",
          marginTop: "70px",
        }}
      >
        {!requested ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "450px" }}>
            <h1>Reset Password</h1>
            <TextBox
              disabled={loading}
              label="Email Address"
              placeholder="Email Address"
              type="email"
              onChange={(e) => { setEmail(e.target.value); }}
              onEnter={recover}
            />
            {loading ? (
              <CircularProgress color="white" style={{ marginTop: "0.5em"}}/>
            ) : (
              <BigButton content="Get Recovery Email" onClick={recover}/>
            )}
            <div style={{ marginTop: "0.5em", fontSize: "10pt", opacity: "0.6"}}>
              <Link to="/signin" style={{ textDecoration: "underline"}}>
                Sign in
              </Link>
            </div>

          </div>
        ) : (
          <div className="fc jc ac" style={{ marginBottom: "250px" }}>
            <h1>Password Reset Requested</h1>
            <div>
              Check your email. We've sent you a link you can use to change your password.
            </div>
            <Link to="/" style={{ marginTop: "30px" }}>
              <img src="/logo_mini.png" height="50px" />
            </Link>
          </div>
        )}
        <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={error} autoHideDuration={4000} onClose={() => { setError() }} >
          <MuiAlert severity="error" variant="filled">{error}</MuiAlert>
        </Snackbar>
      </main>
    </General>
  );
}

export default ForgotPassword;

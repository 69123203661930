import React from 'react';
import { Paper, Button, IconButton } from "@material-ui/core";
import TextBox from "../../../Components/TextBox";
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const diameter = "20px";

class SpendingClass extends React.Component {

  state = {
    editingTitle: true,
  }

  render() {
    const { editingTitle } = this.state;
    const { spendingClass = { title: "Leisure", categories: [], color: "#F44336" } } = this.props;
    const {
      title,
      categories,
      color,
    } = spendingClass;
    return (
      <Paper elevation={2} style={{ padding: "1em", color: "#555", flexGrow: 1, marginBottom: "1em" }} className="fc">
        <div className="fr ac" style={{ alignItems: "center" }}>
          <div className="fc">
            <div className="fr ac">
              {editingTitle ? (
                <TextBox
                  label="Title"
                  placehi
                />
              ) : (
                <div>
                  <h2 style={{ margin: "0", display: "inline" }}>
                    {title}
                  </h2>
                </div>
              )}

              {/*<div*/}
              {/*  style={{*/}
              {/*    width: diameter,*/}
              {/*    height: diameter,*/}
              {/*    borderRadius: "100px",*/}
              {/*    backgroundColor: color,*/}
              {/*    margin: "0px 1em",*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
            <div>
              {categories.length} Categories
            </div>
          </div>
          <div style={{ marginLeft: "auto", fontSize: "20pt", alignItems: "end" }} className="fr">
            {/*<div style={{ marginRight: "0.2em", marginBottom: "0px" }}>$</div>*/}
            <TextBox
              className="center_text_box"
              label="Per Month"
              style={{ maxWidth: "98px", margin: 0 }}
              inputProps={{
                style:{
                  textAlign: "center",
                  fontSize: "16pt"
                }
              }}
            />
          </div>
        </div>
        <div>
        </div>
      </Paper>
    );
  }
}

export default SpendingClass;

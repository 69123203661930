import React from 'react';
import "./Data.css";
import PortalTab from "../PortalTab";
import {Tab, Tabs, Paper, CircularProgress} from "@material-ui/core";
import FaceIcon from '@material-ui/icons/Face';
import Users from "./Users";
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import StorefrontIcon from '@material-ui/icons/Storefront';
import TimelineIcon from '@material-ui/icons/Timeline';
import Locations from "./Locations";
import Merchants from "./Merchants";
import Overview from "./Overview";

class Data extends React.Component {

  state = {
    tab: 0,
    loading: false,
  }

  setIsLoading = (loading) => {
    this.setState({ loading: loading })
  }

  render() {
    const { tab, loading } = this.state;
    const tabs = [
      // { component: <Overview setLoading={this.setIsLoading} />, title: "Overview", icon: <TimelineIcon />},
      { component: <Merchants setLoading={this.setIsLoading} />, title: "Merchants", icon: <StorefrontIcon />},
      { component: <Locations setLoading={this.setIsLoading} />, title: "Locations", icon: <RoomOutlinedIcon />},
      { component: <Users setLoading={this.setIsLoading} />, title: "Users", icon: <FaceIcon />},
      // { component: <Categories setLoading={this.setIsLoading} />, title: "Categories", icon: <CategoryOutlinedIcon/>},
      // { component: <Categories setLoading={this.setIsLoading} />, title: "Buckets", icon: <LocalOfferOutlinedIcon/>},
    ]
    return (
      <PortalTab
        title="Data"
        description="Data and User Information"
        right={(
          <Paper style={{ margin: "auto", width: "max-content" }}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={tab}
              onChange={(e,d) => { this.setState({ tab: d }); }}
              variant="fullWidth"
            >
              >
              {tabs.map((t) => {
                return <Tab
                  label={(
                    <div className="fr ac" style={{ height: "30px" }}>
                      <div style={{ height: "29px", marginRight: "0.5em" }}>{t.icon}</div>
                      <div>{t.title}</div>
                    </div>
                  )}
                />
              })}
            </Tabs>
          </Paper>
        )}
      >
        {loading && (
          <div className="fc ac jc" style={{ width: "100%", margin: "200px 0px" }}>
            <CircularProgress color="primary" style={{  margin: "auto" }} />
            <div>Loading</div>
          </div>
        )}
        <span style={{ display: loading ? "none" : "initial"}}>
          {tabs[tab].component}
        </span>

      </PortalTab>
    );
  }
}

export default Data;

import starbucks from "../../images/logos/starbucks.png";
import seveneleven from "../../images/logos/7eleven.png";
import amazon from "../../images/logos/amazon.png";
import cashapp from "../../images/logos/cashapp.png";
import cvs from "../../images/logos/cvs.png";
import murphy from "../../images/logos/murphy.png";
import netflix from "../../images/logos/netflix.png";
import verizon from "../../images/logos/verizon.png";

const upgradeExamples = [

  {
    "original_name": "Purchase from STARBUCKS STORE 07837",
    "clean_name": "Starbucks",
    "logo": starbucks,
    "recurring": false,
    "category": "Cafe",
    "value": 4.15,
    "date": "Nov 3"
  },
  {
    "original_name": "VRNWRS *7894",
    "clean_name": "Verizon Wireless",
    "logo": verizon,
    "recurring": true,
    "category": "Mobile Phone",
    "value": 49.99,
    "date": "Sep 30"
  },
  {
    "original_name": "AMZN Mktp US*216G364Q0",
    "clean_name": "Amazon",
    "logo": amazon,
    "recurring": false,
    "category": "Online Retailer",
    "value": 145.36,
    "date": "Nov 1"
  },
  {
    "original_name": "CHARGE FROM Netflicom | CA, US | **2413",
    "clean_name": "Netflix",
    "logo": netflix,
    "recurring": true,
    "category": "Digital Subscription",
    "value": 12.99,
    "date": "Oct 20"
  },
  {
    "original_name": "7ELEVEN, 701 S CRATER RD",
    "clean_name": "7-Eleven",
    "logo": seveneleven,
    "recurring": false,
    "category": "Convenience Store",
    "value": 16.14,
    "date": "Oct 20"
  },
  {
    "original_name": "CASH APP*RICK ASTLEY*ADD",
    "clean_name": "Cash App",
    "logo": cashapp,
    "recurring": false,
    "category": "Transfer",
    "value": 86.52,
    "date": "Oct 1"
  },
  {
    "original_name": "CVS/PHARMACY #05, 05087--15 SOUTH MAI",
    "clean_name": "CVS",
    "logo": cvs,
    "recurring": false,
    "category": "Pharmacy",
    "value": 17.32,
    "date": "Sept 30"
  },
  {
    "original_name": "MURPHY EXPRESS 8, 6076 COVINGTON HWY",
    "clean_name": "Murphy Express",
    "logo": murphy,
    "recurring": false,
    "website": "murphyusa.com",
    "category": "Gas Station",
    "value": 26.99,
    "date": "Oct 25"
  },
]

export default  upgradeExamples;
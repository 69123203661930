import React from 'react';
import { Link } from "@reach/router"
import SpadeLogo from "./SpadeLogo"

function Footer(props){
  return (

    <footer style={{
      transform: "translateY(-0px)",
      paddingTop: "60px",
      color: "#5a6be3",
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      fontSize: "12pt"
    }}>
      <div className="fancytext">
        Spade Data, Inc. &copy;  {new Date().getFullYear()}
      </div>
      <div style={{ transform: "translateY(20px)", fontSize: "20pt" }}>
        <Link to="/">
            <SpadeLogo size="30px" style={{ marginBottom: "10px" }} />
        </Link>
      </div>
      <div
        style={{
          color: "#5b64e6",
          opacity: 0.6,
          marginTop: "50px",
          marginBottom: "30px",
          width: "300px",
          display: "flex",
          justifyContent: "space-between",
          textDecoration: "underline",
          fontSize: "10pt"
        }}>
        <div>
          <Link to="/privacy" style={{ color: "#5b64e6" }}>
            Privacy Policy
          </Link>
        </div>
        <div>
          <Link to="/terms" style={{ color: "#5b64e6" }}>
            Terms of Use
          </Link>
        </div>
      </div>
      {/*<img src="/soc2.png" width="75px" alt="soc2 certification"/>*/}
    </footer>
  );
}

export default Footer;

import axios from "axios";
import { generateHeaders } from "../identityActions";
import isLocal from "../isLocal";

const prefix = "/.netlify/functions";

export const buildEndpoint = (path) => {
    path = prefix + path;
    if (isLocal())
        return `//localhost:34567${path}`; // localhost netlify endpoint
    return path;
};

const getUser = () => {
    return window.identity_user;
}

const refreshToken = () => {
    console.log("Refreshing Token...")
    const refresh = window.refresh_function;
    return refresh();
}

const signOut = () => { // TODO allow a sign out from here
    console.log("Session Expired. Logging you out...");
    const logout = window.logoutUser_function;
    logout();
}

// refresh the token and log out if it fails
const genericRequest = (method, path, data) => {
    return new Promise((resolve, reject) => {
        refreshToken().then((token) => {
            axios({
                method: method,
                url: buildEndpoint(path),
                headers: generateHeaders(getUser()),
                data: data,
            })
              .then((r) => {
                  resolve(r);
              })
              .catch((e) => {
                console.log("Failed...")
                const status = e.response?.status;
                if (status == 401) { /// we already tried refreshing so I guess we should log out...
                  signOut()
                }
                reject(e);
            })
        }).catch((e) => {
          console.log("failed to refresh", e);
          const status = e.response?.status;
          if (status == 400) { // When the refresh token is invalid its time to pack it in
            signOut()
          }
            reject(e);
        })
    })
}

export const GET = (path) => { return genericRequest('get', path); };
export const POST = (path, data) => { return genericRequest('post', path, data); };
export const PUT = (path, data) => { return genericRequest('put', path, data); };
export const DELETE = (path, data) => { return genericRequest('delete', path, data); };



import React from 'react';
import General from "../General";
import { Paper, Tabs, Tab } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssessmentIcon from '@material-ui/icons/Assessment';
import TimelineIcon from '@material-ui/icons/Timeline';
import CategoryIcon from '@material-ui/icons/Category';

import Usage from "./Usage/Usage";
import Categories from "./Categories/Categories";
import Reports from "./Reports/Reports";
import Account from "./Account/Account";
import Data from "./Data/Data";

class Portal extends React.Component {

  state = {
    tab: 0,
  }

  render() {
    const { tab } = this.state;
    const { user } = this.props;
    const tabs = [
      { component: <Usage user={user} />, title: "API Usage", icon: <TimelineIcon />},
      // { component: <Data user={user} />, title: "Data", icon: <AssessmentIcon />},
      // { component: <Categories user={user} />, title: "Merchant Categories", icon: <CategoryIcon />},
      // { component: <Reports user={user} />, title: "Buckets", icon: <LocalOfferIcon />},
      { component: <Account user={user} />, title: "Account Settings", icon: <SettingsIcon />},
    ]
    return (
      <General>
        <main style={{
          display: "flex",
          margin: "auto",
          position: "relative",
          zIndex: 10,
          minHeight: "500px",
          flexDirection: "column"
        }}>

          <Paper elevation={2}>
            <Tabs
              indicatorColor="primary"
              textColor="primary"
              value={tab}
              onChange={(e,d) => { this.setState({ tab: d }); }}
              variant="fullWidth"
            >
              >
              {tabs.map((t) => {
                return <Tab label={t.title} icon={t.icon} />
              })}
            </Tabs>
          </Paper>
          <Paper
            elevation={2}
            style={{ width: "100%", padding: "2em", marginTop: "1em", boxSizing: "border-box"}}
          >
            {tabs[tab].component}
          </Paper>
        </main>
      </General>
    );
  }
}

export default Portal;

import React from 'react';
import SpadeMap from "../../../Components/SpadeMap";
import FilterMenu from "../../../Components/FilterMenu";
import { get_locations } from "../../../libs/api/endpoints";

class Locations extends React.Component {

  state = {
    loadingPoints: false,
    locations: [],
  }

  componentDidMount() {
    this.props.setLoading(true);
  }

  loadPoints = (start, end) => {
    this.setState({ loadingPoints: true });
    get_locations(start, end).then((response) => {
      this.setState({ locations: response.data.locations });
    }).finally(() => {
      this.setState({ loadingPoints: false });
      this.props.setLoading(false);
    })
  }

  render() {
    const { locations, loadingPoints } = this.state;
    return (
      <div>
        <h4 style={{ marginBottom: 0 }}>
          Merchant Locations
        </h4>
        <div style={{ marginBottom: "0.5em", fontSize: "10pt" }}>Only merchants with known coordinates will be displayed here</div>
        <div style={{ display: "flex", flexDirection: "row-reverse", flexWrap: "wrap", gap: "1em" }}>
          <FilterMenu
            loadFunc={this.loadPoints}
            loading={loadingPoints}
            loadOnStart
          />
          <SpadeMap locations={locations} style={{ minWidth: "75%", height: "600px", flexGrow: 9999999999 }} />
        </div>
      </div>
    );
  }
}

export default Locations;

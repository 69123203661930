import General from "../General";
import { RedocStandalone } from 'redoc';
import React from 'react';
import "./Docs.css";

class Docs extends React.Component {
  render() {
    return (
      <General renderFooter={false} noScroll={true}>
        <main
          className="documentation"
          style={{
            background: "white"
          }}
        >
          <div
            style={{
              width: "100vw",
              minHeight: "100vh",
              position: "absolute",
              left: "0",
              background: "white",
              transform: "translateY(-25px)"
            }}
          >
            <RedocStandalone
              specUrl="https://v1.prod.api.spadeapi.com/docs.json"
              options={{
                nativeScrollbars: true,
                theme: {
                  colors: { primary: { main: '#5f4dee' } },
                  typography: {
                    fontFamily: "Open Sans",
                    code: {
                      fontFamily: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', *monospace",
                      color: "#5f4dee"
                    }
                  }

                },
              }}
            />
          </div>
        </main>
      </General>
    );
  }
}

export default Docs;

import React from 'react';
import PortalTab from "../PortalTab";
import {Paper, IconButton, CircularProgress, Button} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import TableBody from "@material-ui/core/TableBody";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import {delete_key, get_keys, new_key} from "../../../libs/api/endpoints";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import TextBox from "../../../Components/TextBox";
import {navigate} from "@reach/router";

class Account extends React.Component {

  state = {
    loadingKeys: false,
    generatingKey: false,
    showKeys: false,
    keys: []
  }

  componentDidMount() {
    this.setState({ loadingKeys: true });
    get_keys().then((response) => {
      this.setState({ keys: response.data.keys });
      if (response.data.keys.length == 0) {
        this.setState({ showKeys: true });
      }
    }).finally(() => {
      this.setState({ loadingKeys: false });
    })
  }

  deleteKey = (key) => {
    this.setState({ loadingKeys: true });
    delete_key(key.id).then(() => {
      let { keys } = this.state;
      keys = keys.filter((k) => {
        return k.id != key.id;
      })
      this.setState({ keys: keys });
    }).catch((error) => {
      console.error(error);
    }).finally(() => {
      this.setState({ loadingKeys: false });
    })
  }

  newKey = () => {
    this.setState({ loadingKeys: true });
    new_key().then((response) => {
      const keys = this.state.keys;
      keys.push(response.data);
      this.setState({ keys: keys, loadingKeys: false })
    }).catch((error) => {
      console.error(error);
      this.setState({ loadingKeys: false });
    })
  }

  render() {
    const { keys, showKeys, loadingKeys } = this.state;
    const { user } = this.props;
    const disableButtons = loadingKeys || !showKeys;
    return (
      <PortalTab
        title="Account Settings"
        description="Manage Account Settings"
        right={(
          <div style={{ display: "flex", flexDirection: "column", fontSize: "11pt", marginLeft: "auto", width: "max-content" }}>
            <div> <strong>Email:</strong> {user.email} </div>
            <div> <strong>Name:</strong> {user.user_metadata.full_name} </div>
            <div> <strong>Company:</strong> {user.user_metadata.company} </div>
          </div>
        )}
      >
        <div style={{ width: "max-content" }}>
          <div className="fr ac" style={{ width: "100%" }}>
            <h4 style={{ display: "inline", marginRight: "0.5em" }}>API Keys</h4>
            <div className="fr ac">
              <IconButton size="small" onClick={() => { this.setState({ showKeys: !showKeys }); }} title={showKeys ? "Lock Keys" : "Unlock Keys"}>
                {showKeys ? (
                  <LockOpenOutlinedIcon />
                  ) : (
                  <LockOutlinedIcon />
                  )}
              </IconButton>
              <div style={{ fontSize: "10pt", marginLeft: "0.25em" }}>
                {showKeys ? "Lock to protect keys" : "Unlock to view and manage keys"}
              </div>
            </div>
            {loadingKeys ? (
              <div style={{  marginLeft: "auto", marginRight: "20px", transform: "scale(0.8)" }}>
                <CircularProgress color="primary"/>
              </div>
            ) : (
              <IconButton size="small" title="New API Key" style={{ marginLeft: "auto", marginRight: "20px" }} onClick={this.newKey} disabled={!showKeys}>
                <AddIcon />
              </IconButton>
            )}
          </div>
          <Paper style={{ maxWidth: "max-content", minWidth: "460px" }} elevation={3}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>API Key</TableCell>
                  <TableCell>Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keys.map((key, index) => (
                  <TableRow key={key}>
                    <TableCell>
                      <b>{index + 1}</b>
                    </TableCell>
                    <TableCell style={{ minWidth: "300px", userSelect: showKeys ? "initial" : "none" }}>
                      {showKeys ? key.api_key : "♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️♠️"}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton size="small" title="Delete This API Key" disabled={disableButtons} onClick={() => { this.deleteKey(key); }}>
                        <ClearIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </div>
        <div>
          <h4>Change Password</h4>
          <Button
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
            onClick={() => { navigate("/new_password") }}
            className="fancybackground"
          >
            Change Password
          </Button>
        </div>
        {/*<div>*/}
        {/*  <h4>Change Email Address</h4>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <h4>Change Account Information</h4>*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <h4 style={{ marginBottom: "0.5em" }}>Close Account</h4>*/}
        {/*  <Button style={{ background: "#aa292d", color: "white" }} variant="contained">*/}
        {/*    Delete Account*/}
        {/*  </Button>*/}
        {/*  <div className="fr" style={{ fontSize: "10.5pt", marginTop: "0.5em", color: "#777" }}>*/}
        {/*    <InfoOutlinedIcon fontSize="small" style={{ marginRight: "0.5em"}} />*/}
        {/*    <div>This action can not be undone</div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </PortalTab>
    );
  }
}

export default Account;

import React from 'react';
import { get_merchants } from "../../../libs/api/endpoints";
import Paper from "@material-ui/core/Paper";
import { KeyboardDatePicker } from '@material-ui/pickers';
import Moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import SpadeMap from "../../../Components/SpadeMap";
import {Button, CircularProgress, IconButton} from "@material-ui/core";
import FilterMenu from "../../../Components/FilterMenu";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ClearIcon from "@material-ui/icons/Clear";

const origin = window.location.origin;
let path_origin = "https://v1.api.spade.dev"
if (!origin.includes('spade.dev'))
  path_origin = "http://localhost:8000"


class Merchants extends React.Component {

  state = {
    loadingPoints: false,
    merchants: [],
  }

  componentDidMount() {
    this.props.setLoading(true);
  }

  loadMerchants = (start, end) => {
    this.setState({ loadingPoints: true });
    get_merchants(start, end).then((response) => {
      this.setState({ merchants: response.data.merchants.sort((a,b) => { return b.count - a.count; }) });
    }).catch((e) => {
      // TODO handle error
    }).finally(() => {
      this.setState({ loadingPoints: false });
      this.props.setLoading(false);
    })
  }

  render() {
    const { merchants, loadingPoints } = this.state;
    return (
      <div>
        <h4 style={{ marginBottom: "0.5em" }}>
          Merchants ({merchants.length})
        </h4>
        <div style={{ display: "flex", flexDirection: "row-reverse", flexWrap: "wrap", gap: "1em" }}>
          <FilterMenu
            loadFunc={this.loadMerchants}
            loading={loadingPoints}
            loadOnStart
          />
          <Paper
            style={{
              minWidth: "75%",
              flexGrow: 9999999999,
            }}
            elevation={3}
          >
            <div
              style={{
                overflowY: "auto",
                maxHeight: "70vh",
              }}
            >
              <Table
                size="small"
                stickyHeader
                className="shaded_table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Merchant</TableCell>
                    <TableCell align="right">Transactions</TableCell>
                    <TableCell align="right">Spending</TableCell>
                    <TableCell align="right">Average Transaction</TableCell>
                    <TableCell>City</TableCell>
                    <TableCell>State</TableCell>
                    <TableCell>Logo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {merchants.map((m, index) => (
                    m.name && (
                        <TableRow key={index}>
                          <TableCell style={{ maxWidth: "200px" }}>
                            {m.name}
                          </TableCell>
                          <TableCell align="right">
                            {m.count || 0}
                          </TableCell>
                          <TableCell align="right">
                            ${m.total_spent || 0}
                          </TableCell>
                          <TableCell align="right">
                            ${m.average_spent || 0}
                          </TableCell>
                          <TableCell style={{ textTransform: "Capitalize" }}>
                            {m.city}
                          </TableCell>
                          <TableCell style={{ textTransform: "Capitalize" }}>
                            {m.state}
                          </TableCell>
                          <TableCell>
                            {m.logo ? (
                              <a
                                href={`${path_origin}${m.logo}`}
                                target="_blank"
                                rel="nofollow noopener"
                                style={{
                                  color: "#5f4def",
                                  textDecoration: "none",
                                }}
                              >
                                Logo
                              </a>
                            ) : (
                              <span style={{ color: "#999"}}>
                            Logo
                          </span>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                  ))}
                </TableBody>
              </Table>
              {merchants.length == 0 && (
                <div
                  style={{
                    textAlign: "center",
                    padding: "120px",
                    color: "#999"
                  }}
                >
                  No Transactions
                </div>
              )}
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

export default Merchants;

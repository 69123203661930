import React from 'react';
import {navigate, Router} from "@reach/router";
import Home from "./Scenes/Home/Home";
import { useIdentityContext } from 'react-netlify-identity';
import SignIn from "./Scenes/SignIn/SignIn";
import SignUp from "./Scenes/SignIn/SignUp";
import Portal from "./Scenes/Portal/Portal";
import Success from "./Scenes/Success";
import ForgotPassword from "./Scenes/SignIn/ForgotPassword";
import NewPassword from "./Scenes/SignIn/NewPassword";
import QuickStart from "./Scenes/Other/QuickStart";
import Docs from "./Scenes/Docs/Docs";
import DemoPage from "./Scenes/Other/Demo";
import Privacy from "./Scenes/Documents/Privacy";
import Terms from "./Scenes/Documents/Terms";
import ComingSoon from "./Scenes/Home/ComingSoon";
import MFA from "./Scenes/Documents/MFA";

function Application(){
  const { user, isConfirmedUser, isLoggedIn, param: { token, type }, recoverAccount, getFreshJWT, logoutUser } = useIdentityContext();

  // For password recovery:
  if (token && type === 'recovery' && window.location.pathname === '/') {
    recoverAccount()
      .then((user) => {
        navigate(`/new_password`);
      })
      .catch((err) => {
        console.error(err);
      })
  }

    const loggedIn = user && isConfirmedUser && isLoggedIn;

  if (isLoggedIn) { // logged in
    window.identity_user = user; // make global because im lazy
    window.refresh_function = getFreshJWT;
    window.logoutUser_function = logoutUser;

  } else { // no logged in
    window.identity_user = null;
    window.refresh_function = null;
    window.logoutUser_function = null;
  }

  return (
    <Router>

      {/*<Docs path="/docs"/>*/}
      {/*<QuickStart path="/quickstart" />*/}

      {loggedIn ? ( // logged in pages

        <React.Fragment>
          <NewPassword path="/new_password" />
          {/*<DemoPage path="/tryspade"/>*/}
          {/*<Privacy path="/privacy"/>*/}
          {/*<Terms path="/terms"/>*/}
          {/*<MFA path="/msa"/>*/}
          <Portal user={user} path="/" default/>
        </React.Fragment>

      ) : ( // logged out pages

        <React.Fragment>
          <SignIn path="/signin"/>
          <SignUp path="/protectedsignup"/>
          <ForgotPassword path="/recover"/>
          <Success path="/success"/>
          <Privacy path="/privacy"/>
          <Terms path="/terms"/>
          <MFA path="/msa"/>
          <ComingSoon path="/"  default/>

        </React.Fragment>

      )}
    </Router>
  )

};

export default Application;

import React from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import FaceIcon from '@material-ui/icons/Face';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { get_users, get_user_growth, get_transaction_growth } from "../../../libs/api/endpoints";
import {IconButton} from "@material-ui/core";
import {LineChart, CartesianGrid, XAxis, YAxis, Tooltip,  Line, ResponsiveContainer} from "recharts";
import Moment from 'moment';
import Statistic from "../../../Components/Statistic";
import Code from "../../../Components/Code";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

class Users extends React.Component {

  state = {
    customers: [],
    growth: [],
    spending: [],
  }

  componentDidMount() {
    this.props.setLoading(true);
    Promise.all([get_users(), get_user_growth(), get_transaction_growth()]).then((results) => {
      let total = 0;

      const growth = results[1].data.rows.map((r) => {
        total += parseInt(r.count);
        return {
          count: parseInt(r.count),
          month: Moment(r.month).format("MMM"),
          total: total,
        }
      });

      const spending = results[2].data.rows.map((r, index) => {
        return {
          spent: r.total_spent || 0,
          enrichments: parseInt(r.total_enrichments || 0),
          month: Moment(r.month).format("MMM"),
          spent_norm: Math.round((r.total_spent || 0) / (growth[index].total || 1)), // avoid divide by zero
        }
      })

      this.setState({
        customers: results[0].data.rows,
        growth: growth,
        spending: spending,
      });
      this.props.setLoading(false);
    })
  }

  render() {
    const { customers, growth, spending } = this.state;
    let transactionsThisMonth = 0;
    let totalTransactions = 0;
    let totalSpending = 0;
    customers.forEach((c) => {
      totalTransactions += c.num_transactions ? parseFloat(c.num_transactions) : 0;
      totalSpending += c.spending ? parseFloat(c.spending) : 0;
    })
    let spendingThisMonth = 0;
    let newUsersThisMonth = 0;
    if (spending.length > 0) {
      transactionsThisMonth = spending[spending.length - 1].enrichments;
      spendingThisMonth = spending[spending.length - 1].spent;
    }
    if (growth.length > 0) {
      newUsersThisMonth = growth[growth.length - 1].count;
    }
    console.log(totalSpending);
    return (
      <div className="fc" style={{ flexWrap: "wrap", justifyContent: "space-between" }}>
        <div>
          <h4>Metrics</h4>
          <div className="fr" style={{ flexWrap: "wrap", justifyContent: "space-around" }}>
            <Statistic number={customers.length} title="Total Users"/>
            <Statistic number={newUsersThisMonth} title="New Users This Month"/>
            <Statistic number={totalTransactions} title="Total Enrichments"/>
            <Statistic number={transactionsThisMonth} title="Enrichments This Month"/>
            <Statistic number={totalSpending} dollars title="Total Spending"/>
            <Statistic number={spendingThisMonth} dollars title="Spending This Month"/>
          </div>
        </div>
        <div>
          <h4 style={{ marginBottom: "0.5em" }}>Users ({customers.length})</h4>
          <Paper style={{ width: "100%", maxHeight: "400px", overflowY: "scroll" }} elevation={3}>
            <Table size="small" stickyHeader className="shaded_table">
              <TableHead >
                <TableRow>
                  <TableCell>User ID</TableCell>
                  {/*<TableCell textAlign="center">Age</TableCell>*/}
                  {/*<TableCell textAlign="center">Sex</TableCell>*/}
                  <TableCell align="right">Total Transactions</TableCell>
                  <TableCell align="right">Transactions This Month</TableCell>
                  <TableCell align="right">Total Spending</TableCell>
                  <TableCell align="right">Spending This Month</TableCell>
                  {/*<TableCell align="right">Top Category</TableCell>*/}
                  {/*<TableCell align="center">Profile</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((row) => {
                  return (
                    <TableRow key={row.customer_id}>
                      <TableCell>{row.customer_id}</TableCell>
                      {/*<TableCell textAlign="center"><HelpOutlineIcon style={{ color: "#aaa" }}/></TableCell>*/}
                      {/*<TableCell textAlign="center"><HelpOutlineIcon style={{ color: "#aaa" }}/></TableCell>*/}
                      <TableCell align="right">{(row.num_transactions || 0).toLocaleString()}</TableCell>
                      <TableCell align="right">{(row.num_transactions_this_month || 0).toLocaleString()}</TableCell>
                      <TableCell align="right">${(row.spending || 0).toLocaleString()}</TableCell>
                      <TableCell align="right">${(row.spending_this_month || 0).toLocaleString()}</TableCell>
                      {/*<TableCell></TableCell>*/}
                      {/*<TableCell align="center">*/}
                      {/*  <IconButton size="small" title="View User Profile">*/}
                      {/*    <FaceIcon />*/}
                      {/*  </IconButton>*/}
                      {/*</TableCell>*/}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
          <div className="fr ac" style={{ fontSize: "10pt", marginTop: "0.5em" }}>
            <InfoOutlinedIcon fontSize="small" style={{ marginRight: "0.25em" }} />
            For users to appear here, include an anonymized <Code>user_id</Code> on your enrichment requests
          </div>
        </div>
        <div className="fr" style={{ flexWrap: "wrap", maxWidth: "100%", marginRight: "2em" }}>
          <div style={{ minWidth: "50%", flexGrow: 1 }}>
            <h4 style={{ marginBottom: "0.5em", textAlign: "center", marginTop: "33px" }}>New Users</h4>
            <ResponsiveContainer height={300} >
              <LineChart
                data={growth.slice(growth.length - 12, growth.length)}
              >
                <CartesianGrid strokeDasharray="3 3" strokeWidth={1} />
                <XAxis dataKey="month" name="Month" />
                <YAxis yAxisId="growth" allowDecimals={false}/> >
                <Tooltip isAnimationActive={false} />
                <Line type="monotone" yAxisId="growth" dataKey="count" name="New Users" stroke="#5f4dee" strokeWidth={4} activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div style={{ minWidth: "50%", flexGrow: 1 }}>
            <h4 style={{ marginBottom: "0.5em", textAlign: "center", marginTop: "33px" }}>Total Users</h4>
            <ResponsiveContainer height={300} >
              <LineChart
                data={growth.slice(growth.length - 12, growth.length)}
              >
                <CartesianGrid strokeDasharray="3 3" strokeWidth={1} />
                <XAxis dataKey="month" name="Month" />
                <YAxis yAxisId="total" allowDecimals={false} />
                <Tooltip isAnimationActive={false} />
                <Line type="monotone" yAxisId="total" dataKey="total" name="Total Users" stroke="#82ca9d" strokeWidth={4} activeDot={{ r: 8 }}/>
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div style={{ minWidth: "50%", flexGrow: 1 }}>
            <h4 style={{ marginBottom: "0.5em", textAlign: "center", marginTop: "33px" }}>User Spending</h4>
            <ResponsiveContainer height={300} >
              <LineChart
                data={spending.slice(spending.length - 12, spending.length)}
              >
                <CartesianGrid strokeDasharray="3 3" strokeWidth={1} />
                <XAxis dataKey="month" name="Month" />
                <YAxis allowDecimals={false}/>
                <Tooltip isAnimationActive={false} />

                <Line type="monotone" dataKey="spent_norm" name="Spending Per User" stroke="#BA69A0" strokeWidth={4} activeDot={{ r: 8 }}/>
                <Line type="monotone" dataKey="spent" name="Spending" stroke="orange" strokeWidth={4} activeDot={{ r: 8 }}/>
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div style={{ minWidth: "50%", flexGrow: 1 }}>
            <h4 style={{ marginBottom: "0.5em", textAlign: "center", marginTop: "33px" }}>User Transactions</h4>
            <ResponsiveContainer height={300} >
              <LineChart
                data={spending.slice(spending.length - 12, spending.length)}
              >
                <CartesianGrid strokeDasharray="3 3" strokeWidth={1} />
                <XAxis dataKey="month" name="Month" />
                <YAxis allowDecimals={false} />
                <Tooltip isAnimationActive={false} />
                <Line type="monotone" dataKey="enrichments" name="Transactions" stroke="dodgerblue" strokeWidth={4} activeDot={{ r: 8 }}/>
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}

export default Users;

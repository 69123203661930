import { GET, POST, DELETE } from "./fe_requests";
import buildURL from 'build-url';

export const new_key = () => {
  return POST("/key/");
}

export const get_keys = () => {
  return GET("/key/");
}

export const delete_key = (keyID) => {
  return DELETE(`/key/${keyID}`);
}

export const get_usage = () => {
  return GET("/usage/");
};

export const demo_enrich = (payload) => {
  return POST("/demoenrichment/", payload);
};

export const get_users = () => {
  return GET("/profiles/users");
};

export const get_user_growth = () => {
  return GET("/profiles/user_growth");
};

export const get_transaction_growth = () => {
  return GET("/profiles/transaction_growth");
};

export const get_locations = (start, end) => {
  return GET(buildURL(
      '/location',
      {
        queryParams: {
          start: start,
          end: end,
        }
      }
    ));
};

export const get_merchants = (start, end) => {
  return GET(buildURL(
    '/merchant',
    {
      queryParams: {
        start: start,
        end: end,
      }
    }
  ));
};


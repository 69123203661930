import React, {useState} from 'react';
import General from "../General";
import { CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import TextBox from "../../Components/TextBox";
import BigButton from "../../Components/BigButton";
import {Link, navigate} from "@reach/router";
import { useIdentityContext } from "react-netlify-identity"

const super_secret_keys = [
  '7v2RBe832e',
  'AfRtkSd28V',
  'txFtJ8dHwh',
  'b8c9xQTyH3'
]

function sleep(seconds) {
  var e = new Date().getTime() + (seconds * 1000);
  while (new Date().getTime() <= e) {}
}

function SignUp(){
  const [ email, setEmail ] = useState();
  const [ password, setPassword ] = useState("");
  const [ password2, setPassword2 ] = useState("");
  const [ company, setCompany ] = useState("");
  const [ name, setName ] = useState("");
  const [ key, setKey ] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);

  const { signupUser, logoutUser } = useIdentityContext()

  const validateEmail = (email) => {
    const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  }

  const signUp = () => {
    if (name.length < 1 || password.length < 1 || password2.length < 1 || company.length < 1 || email.length < 1 || key < 1) {
      return setError("All fields are required");
    }
    if (!validateEmail(email)) {
      return setError("Invalid Email");
    }
    if (password != password2) {
      return setError("Passwords do not match")
    }
    if (!super_secret_keys.includes(key)) {
      setLoading(true);
      sleep(1.5);
      setLoading(false);
      return setError("Invalid Partner Key")
    }

    setLoading(true);
    setError();
    setTimeout(() => {
      signupUser(
        email,
        password,
        {
          full_name: name,
          company: company,
        },
        false
      )
        .then((user) => {
          setTimeout(() => {
            navigate("/success")
          },100)
        })
        .catch((err) => {
          console.error(err);
          setError(err.message);
        })
    }, 400)
  }

  return (
    <General>
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
          position: "relative",
          zIndex: 10,
          width: "100%",
          boxSizing: "border-box",
          marginTop: "70px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "450px" }}>
          <h1>Sign Up</h1>

          <TextBox onEnter={signUp} disabled={loading} label="Full Name" placeholder="Name" onChange={(e) => { setName(e.target.value); }}/>
          <TextBox onEnter={signUp} disabled={loading} label="Company" placeholder="Company" onChange={(e) => { setCompany(e.target.value); }}/>
          <TextBox onEnter={signUp} disabled={loading} label="Email Address" placeholder="Email Address" type="email" onChange={(e) => { setEmail(e.target.value); }}/>
          <TextBox onEnter={signUp} disabled={loading} label="Password" placeholder="Password" type="password" onChange={(e) => { setPassword(e.target.value); }}/>
          <TextBox onEnter={signUp} disabled={loading} label="Repeat Password" placeholder="Password" type="password" onChange={(e) => { setPassword2(e.target.value); }}/>
          <TextBox onEnter={signUp} disabled={loading} label="Partner Key" placeholder="Partner Key" onChange={(e) => { setKey(e.target.value); }}/>
          {loading ? (
            <CircularProgress color="white" style={{ marginTop: "0.5em"}}/>
          ) : (
            <BigButton content="Register" onClick={signUp}/>
          )}

          <div style={{ marginTop: "1em", fontSize: "10pt", opacity: "0.6"}}>
            Already Registered?&nbsp;
            <Link to="/signin" style={{ textDecoration: "underline"}}>
              Sign in
            </Link>
          </div>

        </div>
      </main>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={error} autoHideDuration={4000} onClose={() => { setError() }} >
        <MuiAlert severity="error" variant="filled">{error}</MuiAlert>
      </Snackbar>
    </General>
  );
}

export default SignUp;

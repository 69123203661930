import React from "react";
import examples from "../Scenes/Home/examples";
import { navigate } from "@reach/router";
import Paper from "@material-ui/core/Paper";
import JSONPretty from "react-json-pretty";

class ExampleEnrichments extends React.Component {


  state = {
    example: Math.floor(Math.random()*examples.length),
  }

  incrementExample = () => {
    const { example } = this.state;
    this.setState({ example: example + 1 });
  }

  componentDidMount() {

    this.updateInterval = setInterval(this.incrementExample, 8000)
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval);
  }

  render(){
    const { example } = this.state;
    return (
      <div
        className="hide_when_small"
        style={{
          height: "650px",
          width: "400px",
          fontSize: "10pt",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "75px",
          marginRight: "50px",
          // transform: "perspective(1200px) translateY(-30px) rotateY(-15deg)"
        }}
      >
        {/*<div>*/}
        {/*  <h2>Example Enrichment</h2>*/}
        {/*</div>*/}
        {examples.map((e, index) => {
          return (
            <div style={{ height: "0px", opacity: index == example%examples.length ? 1 : 0, transition: "opacity 1s ease" }}>
              <div

                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // transform: "perspective(1200px) translateY(-30px) rotateY(-15deg)"
                }}
              >
                <Paper  levation={3} style={{ borderRadius: "10px", background: "none" }}>
                  <JSONPretty
                    data={JSON.stringify(e.request,null, 3)}
                    style={{ background: "#272822", padding: "0.5em 2em 0.5em 1em", color: "#5f4dee", borderRadius: "10px" }}
                  />
                </Paper>
                <div style={{ fontSize: "30pt", fontWeight: "bold", padding: "10px 0px 5px 10px" }}>↓</div>
                <div style={{ display: "flex", alignItems: "center"}}>
                  <Paper elevation={3} style={{  borderRadius: "10px", background: "none" }}>
                    <JSONPretty
                      data={JSON.stringify(e.response,null, 3)}
                      style={{ background: "#272822", padding: "0.5em 2em 0.5em 1em", color: "#5f4dee", borderRadius: "10px" }}
                    />
                  </Paper>
                </div>
                <div style={{ width: "200px", height: "200px", justifyContent: "center", alignItems: "center", transform: "translate(185px, -110px)"}} className="fr">
                  <img src={e.image} style={{ maxWidth: "190px", maxHeight: "120px", filter: "drop-shadow(5px 5px 5px #31373d)"}}/>
                </div>
              </div>

            </div>
          )
        })}

      </div>
    )
  }

}

export default ExampleEnrichments;

import React from 'react';

const maskedImage = {
  width: "300px",
  height: "120px",
  maskRepeat: "no-repeat",
  WebkitMaskRepeat: "no-repeat",
  maskSize: "220px",
  WebkitMaskSize: "220px",
  maskPosition: "center",
  WebkitMaskPosition: "center",
}

class TrustedBy extends React.Component {


  render() {
    return (
      <React.Fragment>
        <div className="fc ac" style={{ width: "100%", marginTop: "50px"}}>
          <h2 className="fancytext" style={{ marginBottom: "1em" }}>Trusted By</h2>
          <div className="fr" style={{ justifyContent: "space-evenly", width: "100%", flexWrap: "wrap" }}>
            <a href="https://www.lance.app/" target="_blank">
              <div className="fancybackground" style={{...maskedImage, maskImage: "url(/trustedby/lance.svg)", WebkitMaskImage: "url(/trustedby/lance.svg)"}} />
            </a>
            <a href="https://joindaylight.com/" target="_blank">
              <div className="fancybackground" style={{...maskedImage, maskImage: "url(/trustedby/daylight.svg)", WebkitMaskImage: "url(/trustedby/daylight.svg)"}} />
            </a>
            <a href="https://www.quiltt.io/" target="_blank">
              <div className="fancybackground" style={{...maskedImage, maskImage: "url(/trustedby/quiltt.svg)", WebkitMaskImage: "url(/trustedby/quiltt.svg)"}} />
            </a>
            <a href="https://getfrich.com" target="_blank">
              <div className="fancybackground" style={{...maskedImage, maskImage: "url(/trustedby/frichmask.svg)", WebkitMaskImage: "url(/trustedby/frichmask.png)"}} />
            </a>
          </div>
        </div>
        <div className="fc ac" style={{ width: "100%", marginTop: "50px"}}>
          <h2 className="fancytext" style={{ marginBottom: "2em" }}>Supported By</h2>
          <div className="fr" style={{ justifyContent: "center", width: "100%", flexWrap: "wrap", gap: "10px"  }}>
            <a href="https://www.gradient.com/" target="_blank" style={{ marginBottom: "1em"}}>
              <div className="fancybackground" style={{...maskedImage, WebkitMaskSize: "auto 120px", maskSize: "auto 120px", maskImage: "url(/trustedby/gv.png)", WebkitMaskImage: "url(/trustedby/gv.png)"}} />
            </a>
            <a href="https://www.ycombinator.com/" target="_blank">
              <div className="fancybackground" style={{...maskedImage, WebkitMaskSize: "auto 120px", maskSize: "auto 120px", maskImage: "url(/trustedby/yc.png)", WebkitMaskImage: "url(/trustedby/yc.png)"}} />
            </a>
            <a href="https://www.thefund.vc/" target="_blank">
              <div className="fancybackground" style={{...maskedImage, WebkitMaskSize: "auto 120px", maskSize: "auto 120px", maskImage: "url(/thefund/yc.png)", WebkitMaskImage: "url(/trustedby/thefund.png)"}} />
            </a>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TrustedBy;
